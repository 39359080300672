import React, { useState, useEffect, type ReactElement } from "react";
import type {
	Artist,
	HomepageContent,
	OnDisplayThumbnail,
} from "./Components/interfaces";
import {
	modifyArtists,
	readAllArtists,
	readSingleArtist,
	deleteArtist,
	readHomepageContent,
	modifyHomepageContent,
	readOnDisplayThumbnails,
	modifyOnDisplayThumbnails,
	// readAllOnDisplayThumbnails,
} from "./Components/FirestoreDB";
import InputFormArtists from "./Components/InputFormArtists";
import InputFormHomepage from "./Components/InputFormHomepage";
import InputFormOnDisplay from "./Components/InputFormOnDisplay";
// import type { DocumentSnapshot } from "firebase/firestore";

interface PropsDDM {
	artists: Artist[];
	selectedArtist: string | undefined;
	onSelectArtist: (selectedArtistName: string) => void;
}

const DropdownMenu: React.FC<PropsDDM> = ({
	artists,
	selectedArtist,
	onSelectArtist,
}) => {
	const handleSelectItem = (
		event: React.ChangeEvent<HTMLSelectElement>,
	): void => {
		onSelectArtist(event.target.value);
	};
	return (
		<select value={selectedArtist} onChange={handleSelectItem}>
			<option key={0} value={undefined}></option>
			{artists.map((artist, index) => (
				<option
					key={`${artist.inId} ${index + 1}`}
					value={artist.filePath}
				>
					{artist.name}
				</option>
			))}
		</select>
	);
};

// const ArtistsList: React.FC<PropsArtistsLists> = ({ artists, modifyArtists }) => {
//   return (
//     <div>
//       {artists.map((artist, index) => (
//         <button key={index} onClick={() => modifyArtists(artist)}>
//           {artist.name}
//         </button>
//       ))}
//     </div>
//   );
// };

function AdminPage(): ReactElement {
	const [artists, setArtists] = useState<Artist[]>([]);
	const [selectedArtistPath, setSelectedArtistPath] = useState<
		string | undefined
	>(undefined);
	const [selectedArtist, setSelectedArtist] = useState<Artist | undefined>(
		undefined,
	);
	const [isDeleting, setIsDeleting] = useState(false);
	const [homepageContent, setHomepageContent] = useState<
		HomepageContent | undefined
	>(undefined);
	const [ODThumbnails, setODThumbnails] = useState<
		OnDisplayThumbnail[] | undefined
	>(undefined);
	// const [cursor, setCursor] = useState<undefined | DocumentSnapshot>(
	// 	undefined,
	// );

	useEffect(() => {
		const fetchAllArtists = async (): Promise<void> => {
			try {
				const artistData = await readAllArtists();
				setArtists(artistData);
			} catch (error) {
				console.log(`Error setting artist data @ admin page`);
			}
		};

		fetchAllArtists()
			.then(() => {
				console.log("Artists successfully loaded!");
			})
			.catch((error) => {
				console.error("Error fetching all artists (UseEffect1)!", {
					error,
				});
			});
	}, [selectedArtistPath]);

	// ...

	useEffect(() => {
		// Reads the data of the selected artist
		const fetchSelectedArtist = async (): Promise<void> => {
			try {
				const selectedArtistData =
					await readSingleArtist(selectedArtistPath);
				setSelectedArtist(selectedArtistData);
			} catch (error) {
				console.log(`Error setting selected artist data @ admin page`);
			}
		};

		if (selectedArtistPath !== undefined) {
			fetchSelectedArtist()
				.then(() => {
					console.log("Selected artist successfully loaded!");
				})
				.catch((error) => {
					console.error(
						"Error fetching selected artist (UseEffect2.1)",
						{
							error,
						},
					);
				});
		} else if (
			selectedArtistPath === undefined &&
			selectedArtist !== null
		) {
			fetchSelectedArtist()
				.then(() => {
					console.log("Selected artist successfully loaded!");
				})
				.catch((error) => {
					console.error(
						"Error fetching selected artist (UseEffect2.2)",
						{
							error,
						},
					);
				});
		}
	}, [selectedArtistPath]);

	useEffect(() => {
		// Reads the data of the homepage content
		const getHomepageContent = async (): Promise<void> => {
			try {
				const fetchedHomepageContent = await readHomepageContent();
				setHomepageContent(fetchedHomepageContent);
			} catch (error) {
				console.log(
					"Error setting state: homepage content @ admin page",
				);
			}
		};

		getHomepageContent()
			.then(() => {
				console.log("Homepage content successfully loaded!");
			})
			.catch((error) => {
				console.error("Error fetching homepage content (UseEffect3)", {
					error,
				});
			});
	}, []);

	useEffect(() => {
		// Reads all the OD thumbnails
		const fetchODThumbnails = async (): Promise<void> => {
			try {
				const fetchedODThumbnails = await readOnDisplayThumbnails(
					undefined,
					null,
				);
				setODThumbnails(fetchedODThumbnails.thumbnails);
				// setCursor(fetchedODThumbnails.cursor);
			} catch (error) {
				console.log("Error setting state: OD Thumbnails @ admin page");
			}
		};

		fetchODThumbnails()
			.then(() => {
				console.log("OD thumbnails successfully loaded!");
			})
			.catch((error) => {
				console.error("Error fetching OD thumbnails (UseEffect4)", {
					error,
				});
			});
	}, []);

	const handleNewArtist = async (newArtist: Artist): Promise<void> => {
		modifyArtists(newArtist); // update Firestore database first
		setArtists([...artists, newArtist]); // update local state only after successful update to Firestore
	};

	const handleDelete = async (): Promise<void> => {
		if (
			window.confirm(
				"Are you sure you want to delete an artist ? (Irreversible)",
			)
		) {
			try {
				if (selectedArtistPath !== undefined) {
					console.log("Trying to delete");
					setIsDeleting(true);
					await deleteArtist(selectedArtistPath);
					setIsDeleting(false);
					setSelectedArtistPath(undefined);
					setSelectedArtist(undefined);
				} else {
					console.log("Nothing to delete.");
				}
			} catch (error) {
				console.log("Error Deleting");
			}
		}
	};

	const handleSelectArtist = (inputSelection?: string): void => {
		setSelectedArtistPath(inputSelection);
	};

	const handleChangeHomepageContent = async (
		newHomepageContent: HomepageContent,
	): Promise<void> => {
		modifyHomepageContent(newHomepageContent);
		setHomepageContent(newHomepageContent);
	};

	const handleChangeOnDisplay = async (
		newODThumbnails: OnDisplayThumbnail[],
	): Promise<void> => {
		modifyOnDisplayThumbnails(newODThumbnails).catch((error) => {
			console.error(
				"Error Modifying thumbnails (handleChangeOnDisplay)",
				{
					error,
				},
			);
		});
		setODThumbnails(newODThumbnails);
	};

	return (
		<>
			<section id="AdminOptions">
				<DropdownMenu
					artists={artists}
					selectedArtist={selectedArtistPath}
					onSelectArtist={handleSelectArtist}
				></DropdownMenu>
				<div>
					<button
						onClick={() => {
							void handleDelete();
						}}
						disabled={isDeleting}
					>
						Delete
					</button>
				</div>
				<InputFormArtists
					onSubmit={(event) => {
						void handleNewArtist(event);
					}}
					selectedArtist={selectedArtist}
				></InputFormArtists>
			</section>
			{homepageContent !== undefined && (
				<section id="ChangeHomepageContent">
					<InputFormHomepage
						onSubmit={(event) => {
							void handleChangeHomepageContent(event);
						}}
						currentContent={homepageContent}
					></InputFormHomepage>
				</section>
			)}
			{ODThumbnails !== undefined && (
				<section id="ChangeOnDisplayContent">
					<InputFormOnDisplay
						onSubmit={(event) => {
							void handleChangeOnDisplay(event);
						}}
						currentContent={ODThumbnails}
					></InputFormOnDisplay>
				</section>
			)}
		</>
	);
}

export default AdminPage;
