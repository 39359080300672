import React, { type ReactElement, useEffect, useState } from "react";
import { readHomepageContent } from "./Components/FirestoreDB";
import { type HomepageContent, type LatestItem } from "./Components/interfaces";
import { Link } from "react-router-dom";
// import { normaliseString } from "./Components/utilities";

export function HomePage(): ReactElement {
	const [content, setContent] = useState<HomepageContent | undefined>(
		undefined,
	);

	useEffect(() => {
		// Reads all the artists in the db
		const fetchContent = async (): Promise<void> => {
			try {
				const homepageContent = await readHomepageContent();
				setContent(homepageContent);
			} catch (error) {
				console.log("Error loading at homepage: ", error);
			}
		};
		fetchContent();
	}, [content]);

	if (content === undefined) {
		console.log("Loading homepage content...");
		return <div id="LoadingBox" className="loading"></div>;
	} else {
		return (
			<>
				{/* ``<div id="LoadingBox" className="loaded"></div> A nice load in fade */}
				<section className="mainBanner">
					{content.homepageBanner1.redirect !== "" && (
						<Link
							className="coverPhotoMain"
							to={content.homepageBanner1.redirect}
						>
							<img
								src={content.homepageBanner1.URL}
								alt={content.homepageBanner1.subHeading}
							/>
						</Link>
					)}
					{content.homepageBanner1.redirect === "" && (
						<div className="coverPhotoMain">
							<img
								src={content.homepageBanner1.URL}
								alt={content.homepageBanner1.subHeading}
							/>
						</div>
					)}
				</section>
				<section className="currentRelease">
					<div className="currentlyShowingTitle">
						<h2 className="curSho">Currently Showing</h2>
						<br />
						<h3 className="releaseBold">
							{content.homepageBanner2.heading}
						</h3>
						<h4 className="subHeading">
							{content.homepageBanner2.subHeading}
						</h4>
					</div>
					<div className="coverPhotoCurrentRelease">
						{content.homepageBanner2.redirect !== "" && (
							<Link
								className="coverPhotoMain"
								to={content.homepageBanner2.redirect}
							>
								<img
									src={content.homepageBanner2.URL}
									alt={content.homepageBanner2.subHeading}
								/>
							</Link>
						)}
						{content.homepageBanner2.redirect === "" && (
							<>
								<img
									src={content.homepageBanner2.URL}
									alt={content.homepageBanner2.subHeading}
								/>
							</>
						)}
					</div>
				</section>
				<section className="latestEdition">
					<div className="latestEditionHeader">
						<article>
							<h2 className="newEditions">
								{content.Latest.mainHeading}
							</h2>
							<br></br>
							<h3 className="releaseLight">
								{content.Latest.subHeading}
							</h3>
							<a href={`${content.Latest.URLtoArtist}`}>
								<h3 className="releaseBold">
									{content.Latest.artistName}
								</h3>
							</a>
						</article>
					</div>
					<div
						className={
							content.Latest.banner
								? "latestEditionBanner"
								: `homePiecesContainer${content.Latest.numOfItems}`
						}
					>
						{content.Latest.latestItems.map(
							(
								latestItem: LatestItem,
								latestItemIndex: number,
							) => (
								<Link
									key={`latestItem${latestItemIndex + 1}`}
									to={latestItem.artLink}
									className={
										content.Latest.banner
											? "editionImageBanner"
											: "editionImage"
									}
								>
									<img
										key={`latestItem${
											latestItemIndex + 1
										}Image`}
										src={latestItem.imageSrc}
										alt={latestItem.imageSrc}
									/>
									{latestItem.name !== "" &&
										latestItem.price !== "" && (
											<div className="thumbnailCaption">
												{latestItem.name !== "" && (
													<h6>{latestItem.name}</h6>
												)}
												{latestItem.price !== "" && (
													<h6>{latestItem.price}</h6>
												)}
											</div>
										)}
								</Link>
							),
						)}
					</div>
				</section>
				<section className="latestPSC">
					<div className="latestPSCHeader">
						<article>
							<a
								href="https://theprintsubscribersclub.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h2 className="pscTextLogo">
									The Print Subscribers Club
								</h2>
							</a>
							<br />
							<h3 className="releaseLight">Latest Release by</h3>
							<h3 className="releaseBold">
								{content.PSCLatestRelease.artistName}
							</h3>
						</article>
					</div>
					<div className="Gallery">
						<div className="galleryPiece">
							{" "}
							{/* CHANGE BACK TO galleryPiece if no all 3 are identical / p again */}
							<a
								href={content.PSCLatestRelease.link}
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="thumbnail">
									<img
										src={
											content.PSCLatestRelease.piece1.URL
										}
										alt={
											content.PSCLatestRelease.piece1.name
										}
									/>
								</div>
								<div className="thumbnailCaption">
									<h6>
										{content.PSCLatestRelease.piece1.name},{" "}
										{content.PSCLatestRelease.bookYear}
									</h6>
								</div>
							</a>
						</div>
						<div className="galleryPiece">
							{" "}
							{/* CHANGE BACK TO galleryPiece if no all 3 are identical / p again */}
							<a
								href={content.PSCLatestRelease.link}
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="thumbnail">
									<img
										src={
											content.PSCLatestRelease.piece2.URL
										}
										alt={
											content.PSCLatestRelease.piece2.name
										}
									/>
								</div>
								<div className="thumbnailCaption">
									<h6>
										{content.PSCLatestRelease.piece2.name},{" "}
										{content.PSCLatestRelease.bookYear}
									</h6>
								</div>
							</a>
						</div>
						<div className="galleryPiece">
							{" "}
							{/* CHANGE BACK TO galleryPiece if no all 3 are identical / p again */}
							<a
								href={content.PSCLatestRelease.link}
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="thumbnail">
									<img
										src={
											content.PSCLatestRelease.piece3.URL
										}
										alt={
											content.PSCLatestRelease.piece3.name
										}
									/>
								</div>
								<div className="thumbnailCaption">
									<h6>
										{content.PSCLatestRelease.piece3.name},{" "}
										{content.PSCLatestRelease.bookYear}
									</h6>
								</div>
							</a>
						</div>
					</div>
				</section>
			</>
		);
	}
}
