import React, { useState } from "react";
import { Link } from "react-router-dom";

const PopupBanner: React.FC = () => {
	const [isHidden, setIsHidden] = useState<boolean>(false);

	return (
		<div
			className={`popup-banner ${isHidden ? "popup-banner-hidden" : ""}`}
		>
			<p>
				Your privacy is important to us. For more information on how we
				manage data, please read our privacy & cookies policy.
			</p>
			<Link
				className="learnMorePPC"
				onClick={(event) => {
					setIsHidden(!isHidden);
				}}
				to="/ppc"
			>
				Learn More
			</Link>
			<button
				className="close-popup-btn"
				onClick={() => {
					setIsHidden(!isHidden);
				}}
			>
				{isHidden ? "Closing..." : "Close"}
			</button>
		</div>
	);
};

export default PopupBanner;
