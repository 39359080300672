import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { signInWithGoogle, isAdmin } from "./Authenticate";
import AdminPage from "../AdminPage";

const GoogleLoginButton: React.FC = () => {
	const navigate = useNavigate();

	const handleLoginClick = (): void => {
		signInWithGoogle(navigate);
	};

	return (
		<section className="googleLogIn">
			<button className="googleLogInButton" onClick={handleLoginClick}>
				Login with Google
			</button>
		</section>
	);
};

const AdminAccess: React.FC = () => {
	const navigate = useNavigate();
	const auth = getAuth();
	const [userState, setUserState] = useState<
		"pending" | "admin" | "not-admin" | "unauthenticated"
	>("pending");

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user !== null) {
				// If the user is authenticated, check if they are an admin
				if (isAdmin(user.email ?? "")) {
					setUserState("admin");
				} else {
					console.log("Not an admin");
					setUserState("not-admin");
					navigate("/");
				}
			} else {
				setUserState("unauthenticated");
			}
		});

		return () => {
			unsubscribe();
		};
	}, [auth, navigate]);

	if (userState === "pending") {
		return null; // Render nothing while waiting for the auth state
	}

	if (userState === "admin") {
		return <AdminPage />;
	}

	if (userState === "not-admin" || userState === "unauthenticated") {
		return <GoogleLoginButton />;
	}

	return null;
};

export default AdminAccess;
