import React from "react";

const PrivacyPage: React.FC = () => {
	return (
		<section className="privacy-page">
			<h1>Privacy & Cookies</h1>

			<article className="privacy-section">
				<h2>1 - Privacy Policy</h2>
				<h3>1.1 - Introduction</h3>
				<p>
					We recognize the importance of privacy and are firmly
					committed to ensuring the confidentiality and security of
					any information you provide to us through our website. We've
					created this policy to provide you with clarity about how we
					handle your data.
				</p>
				<h3>1.2 - Data Collection and Use</h3>
				<p>
					We strive for data minimization, collecting only what is
					necessary. Currently, we do not gather, store, or process
					any personal data when you visit our site, ensuring you
					maintain your privacy.
				</p>
				<h3>1.3 - Security Measures</h3>
				<p>
					Though we do not collect personal data, we still maintain
					security measures to protect our website from unauthorized
					access, alterations, or any potential breaches. Our
					commitment is to ensure a secure environment for all our
					visitors.
				</p>
				<h3>1.4 - Third-Party Services</h3>
				<p>
					We do not share any data with third parties, nor do we
					integrate with services that might gather your personal data
					for advertising or tracking purposes.
				</p>
			</article>

			<article className="cookies-section">
				<h2>2 - Cookies</h2>
				<h3>2.1 - Use of Cookies</h3>
				<p>
					Our website utilizes only the most essential cookies, vital
					for its optimal functioning and to enhance user experience.
				</p>
				<h3>2.2 - No Tracking or Advertising Cookies</h3>
				<p>
					We assure you that none of the cookies used on our site are
					designed to track your personal activities or gather
					personal information. Additionally, we do not employ cookies
					for advertising or any form of user profiling.
				</p>
				<h3>2.3 - Consent</h3>
				<p>
					By continuing to navigate and use our website, you
					acknowledge and consent to our usage of these necessary
					cookies.
				</p>
			</article>

			<article className="cookies-section">
				<h2>3 - Contact</h2>
				<p>
					If there are any questions or concerns regarding our privacy
					practices or this policy, kindly reach out to us. We are
					always open to feedback and eager to address any of your
					queries.
				</p>
			</article>
		</section>
	);
};

export default PrivacyPage;
