// import React from "react";
import {
	collection,
	doc,
	setDoc,
	getDoc,
	getFirestore,
	getDocs,
	deleteDoc,
	orderBy,
	query,
	startAfter,
	limit,
} from "firebase/firestore";
import type {
	CollectionReference,
	DocumentData,
	DocumentSnapshot,
} from "firebase/firestore";
import { config } from "../Config/Config.js";
import type { FirebaseApp } from "firebase/app";
import { initializeApp } from "firebase/app";
import type {
	Artist,
	ArtistKeyInfo,
	Set,
	Piece,
	HomepageContent,
	LatestItem,
	OnDisplayThumbnail,
	bannerState,
} from "./interfaces";

const firebaseDB: FirebaseApp = initializeApp(config);

export const firestore = getFirestore(firebaseDB);

export const artistCollection: CollectionReference<DocumentData> = collection(
	firestore,
	"Artists",
);
export const onDisplayCollection: CollectionReference<DocumentData> =
	collection(firestore, "OnDisplay");

export function modifyArtists(newSlide: Artist): void {
	const targetArtist = doc(firestore, `Artists/${newSlide.filePath}`); // Target the new/current artist in the Artists collection
	// What is this doing? Seems to just create an identical object
	const docData = {
		// Converts data into JSON format for upload to firebase
		name: newSlide.name,
		filePath: newSlide.filePath,
		inId: newSlide.inId,
		createdAt: newSlide.createdAt,
		lead: {
			name: newSlide.lead.name,
			src: newSlide.lead.src,
		},
		topCopy: newSlide.topCopy,
		topImage: {
			src: newSlide.topImage.src,
			alt: newSlide.topImage.alt,
		},
		sets: newSlide.sets.map((set: Set) => ({
			name: set.name,
			numOfPieces: set.numOfPieces,
			workDescription: set.workDescription,
			pdf: set.pdf,
			pieces: set.pieces.map((piece: Piece) => ({
				nameSuffix: piece.nameSuffix,
				shortDescription: piece.shortDescription,
				sizePlate: piece.sizePlate,
				size: piece.size,
				edition: piece.edition,
				pricing: piece.pricing,
				code: piece.code,
				image: {
					src: piece.image.src,
					alt: piece.image.alt,
				},
			})),
		})),
	};

	setDoc(targetArtist, docData, { merge: true })
		.then(() => {
			console.log(`Uploaded to field: ${newSlide.filePath}`);
		})
		.catch((error: Error) => {
			console.log(`Error when modifying artist: ${error.message}`);
		});
}

export async function readSingleArtist(
	givenArtist?: string,
): Promise<Artist | undefined> {
	// console.log(givenArtist); debug
	if (givenArtist === undefined) {
		return;
	}
	const targetArtist = doc(firestore, `Artists/${givenArtist}`);
	const artistSnapshot = await getDoc(targetArtist);
	if (artistSnapshot.exists()) {
		const docData = artistSnapshot.data();
		// console.log(`My data is ${JSON.stringify(docData)}`);
		const readArtist: Artist = docData as Artist;
		return readArtist;
	} else {
		// Should this be an error?
		console.log("Can't read artist -> doesn't exist!");
		return undefined;
	}
}

export async function readAllArtistsKeyInfo(): Promise<ArtistKeyInfo[]> {
	// console.log("test");
	try {
		const artistCollection = collection(firestore, "Artists");
		const querySnapshot = await getDocs(artistCollection);
		const artistKeyInfo: ArtistKeyInfo[] = [];
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			artistKeyInfo.push({
				name: data.name,
				filePath: data.filePath,
				inId: data.inId,
				createdAt: data.createdAt,
				lead: {
					name: data.lead.name,
					src: data.lead.src,
				},
			});
		});
		return artistKeyInfo;
	} catch (error) {
		console.error("Error reading artist key info: ", error); // Display an error message in the console
		return [];
	}
}

export async function readAllArtists(): Promise<Artist[]> {
	// console.log("test");
	try {
		const artistCollection = collection(firestore, "Artists");
		const querySnapshot = await getDocs(artistCollection);
		const artists: Artist[] = [];
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			artists.push({
				name: data.name,
				filePath: data.filePath,
				inId: data.inId,
				createdAt: data.createdAt,
				lead: {
					name: data.lead.name,
					src: data.lead.src,
				},
				topCopy: data.topCopy,
				topImage: data.topImage,
				sets: data.sets,
			});
		});
		return artists; // Display the IDs in the console
	} catch (error) {
		console.error("Error reading all artists: ", error); // Display an error message in the console
		return [];
	}
}

export const deleteArtist = async (artistPath: string): Promise<void> => {
	const artistCollectionRef = collection(firestore, "Artists");
	const artistDocRef = doc(artistCollectionRef, artistPath);
	try {
		await deleteDoc(artistDocRef);
		console.log("Document successfully deleted!");
	} catch (error) {
		console.error("Error when deleting artist: ", error);
	}
};

export function modifyHomepageContent(homepageContent: HomepageContent): void {
	const targetPage = doc(firestore, "Homepage", "content"); // Target the homepage content
	// console.log(homepageContent.Latest.setName); DEBUG
	const docData = {
		// Converts data into JSON format for upload to firebase
		homepageBanner1: {
			URL: homepageContent.homepageBanner1.URL,
			heading: homepageContent.homepageBanner1.heading,
			subHeading: homepageContent.homepageBanner1.subHeading,
			redirect: homepageContent.homepageBanner1.redirect,
			lightHeader: homepageContent.homepageBanner1.lightHeader,
		},
		homepageBanner2: {
			URL: homepageContent.homepageBanner2.URL,
			heading: homepageContent.homepageBanner2.heading,
			subHeading: homepageContent.homepageBanner2.subHeading,
			redirect: homepageContent.homepageBanner2.redirect,
			lightHeader: homepageContent.homepageBanner2.lightHeader,
		},
		PSCLatestRelease: {
			artistName: homepageContent.PSCLatestRelease.artistName,
			bookName: homepageContent.PSCLatestRelease.bookName,
			bookYear: homepageContent.PSCLatestRelease.bookYear,
			link: homepageContent.PSCLatestRelease.link,
			piece1: {
				name: homepageContent.PSCLatestRelease.piece1.name,
				URL: homepageContent.PSCLatestRelease.piece1.URL,
			},
			piece2: {
				name: homepageContent.PSCLatestRelease.piece2.name,
				URL: homepageContent.PSCLatestRelease.piece2.URL,
			},
			piece3: {
				name: homepageContent.PSCLatestRelease.piece3.name,
				URL: homepageContent.PSCLatestRelease.piece3.URL,
			},
		},
		Latest: {
			banner: homepageContent.Latest.banner,
			lightHeader: homepageContent.Latest.lightHeader,
			mainHeading: homepageContent.Latest.mainHeading,
			subHeading: homepageContent.Latest.subHeading,
			artistName: homepageContent.Latest.artistName,
			URLtoArtist: homepageContent.Latest.URLtoArtist,
			setName: homepageContent.Latest.setName,
			latestItems: homepageContent.Latest.latestItems.map(
				(latestItem: LatestItem) => ({
					name: latestItem.name,
					imageSrc: latestItem.imageSrc,
					imageAlt: latestItem.imageAlt,
					artLink: latestItem.artLink,
					price: latestItem.price,
				}),
			),
			numOfItems: homepageContent.Latest.numOfItems,
		},
	};
	setDoc(targetPage, docData, { merge: true })
		.then(() => {
			console.log(`Uploaded to homepage!`);
		})
		.catch((error: Error) => {
			console.log(
				`Error modifying the homepage content: ${error.message}`,
			);
		});
}

export async function readHomepageContent(): Promise<
	HomepageContent | undefined
> {
	const targetPage = doc(firestore, "Homepage", "content");
	const contentSnapshot = await getDoc(targetPage);
	if (contentSnapshot.exists()) {
		const content = contentSnapshot.data() as HomepageContent;
		return content;
	} else {
		console.error("Sorry, failed to load homepage content!");
	}
}

// A new function to just fetch the state of the banners
export const readBannerStates = async (): Promise<bannerState> => {
	const targetPage = doc(firestore, "Homepage", "content");
	const contentSnapshot = await getDoc(targetPage);
	if (contentSnapshot.exists()) {
		const content = contentSnapshot.data() as HomepageContent; // Returns an object with { banner1Light: ..., banner2Light: ... }
		const B1lightHeader = content.homepageBanner1.lightHeader;
		const B2lightHeader = content.homepageBanner2.lightHeader;
		const B3lightHeader = content.Latest.lightHeader;
		return {
			B1lightHeader,
			B2lightHeader,
			B3lightHeader,
		};
	} else {
		// Doc not found
		console.log(`Couldn't find homepage banner light/dark preferences...`);
		return {
			B1lightHeader: false,
			B2lightHeader: false,
			B3lightHeader: false,
		}; // Default state or throw an error
	}
};

export async function readSingleThumbnail(
	givenThumbnailPath?: string,
): Promise<OnDisplayThumbnail | undefined> {
	// console.log(givenThumbnailPath); debug
	if (givenThumbnailPath === undefined) {
		return;
	}
	const targetThumbnailContent = doc(
		firestore,
		`OnDisplay/${givenThumbnailPath}`,
	);
	const thumbnailContentSnapshot = await getDoc(targetThumbnailContent);
	if (thumbnailContentSnapshot.exists()) {
		const docData = thumbnailContentSnapshot.data();
		// console.log(`My data is ${JSON.stringify(docData)}`); DEBUG
		const readThumbnailContent: OnDisplayThumbnail =
			docData as OnDisplayThumbnail;
		return readThumbnailContent;
	} else {
		console.log("Can't read artist -> doesn't exist!");
	}
}

export async function readOnDisplayThumbnails(
	searchLimit: number | undefined,
	cursor: DocumentSnapshot | undefined | null,
): Promise<{
	thumbnails: OnDisplayThumbnail[];
	cursor: DocumentSnapshot | null;
}> {
	if (searchLimit === undefined) {
		searchLimit = 10000;
	}
	try {
		let q;
		if (cursor === null) {
			q = onDisplayCollection;
		} else if (cursor === undefined) {
			q = query(
				onDisplayCollection,
				orderBy("__name__", "desc"),
				limit(searchLimit),
			);
		} else {
			q = query(
				onDisplayCollection,
				orderBy("__name__", "desc"),
				startAfter(cursor),
				limit(searchLimit),
			);
		}
		const querySnapshot = await getDocs(q);
		const onDisplayThumbnails: OnDisplayThumbnail[] = [];
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			onDisplayThumbnails.push({
				type: data.type,
				heading1: data.heading1,
				heading2: data.heading2,
				description: data.description,
				imgSrc: data.imgSrc,
				imgAlt: data.imgAlt,
				path: data.path,
				hasArtwork: data.hasArtwork,
				pageStyle: data.pageStyle,
			});
		});

		if (cursor !== null) {
			return {
				thumbnails: onDisplayThumbnails,
				cursor: querySnapshot.docs[querySnapshot.docs.length - 1],
			};
		} else {
			return {
				thumbnails: onDisplayThumbnails,
				cursor: null,
			};
		}
	} catch (error) {
		console.error("Error reading On Display Item: ", error);
		return { thumbnails: [], cursor: null };
	}
}

export async function readAllOnDisplayThumbnails(): Promise<
	OnDisplayThumbnail[] | []
> {
	try {
		const querySnapshot = await getDocs(onDisplayCollection);
		const onDisplayThumbnails: OnDisplayThumbnail[] = [];
		// let index = 0;
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			onDisplayThumbnails.push({
				type: data.type,
				heading1: data.heading1,
				heading2: data.heading2,
				description: data.description,
				imgSrc: data.imgSrc,
				imgAlt: data.imgAlt,
				path: data.path,
				hasArtwork: data.hasArtwork,
				pageStyle: data.pageStyle,
			});
			// index++;
		});
		return onDisplayThumbnails; // Display the IDs in the console
	} catch (error) {
		console.error("Error reading On Display Item: ", error); // Display an error message in the console
		return [];
	}
}

export async function modifyOnDisplayThumbnails(
	onDisplayThumbnails: OnDisplayThumbnail[],
): Promise<void> {
	try {
		//   let index = 0;
		// Update or add each slide
		await Promise.all(
			// USEFUL CHANGE ALL
			onDisplayThumbnails.map(async (thumbnail) => {
				// Each slide
				const docData = {
					type: thumbnail.type,
					heading1: thumbnail.heading1,
					heading2: thumbnail.heading2,
					description: thumbnail.description,
					imgSrc: thumbnail.imgSrc,
					imgAlt: thumbnail.imgAlt,
					path: thumbnail.path,
					hasArtwork: thumbnail.hasArtwork,
					pageStyle: thumbnail.pageStyle,
				};

				const slideDocRef = doc(onDisplayCollection, docData.path);
				// index++;
				const slideDocSnapshot = await getDoc(slideDocRef);

				if (slideDocSnapshot.exists()) {
					// Update existing document
					await setDoc(slideDocRef, docData, { merge: true });
					console.log(
						`Updated document with ID: ${slideDocSnapshot.id}`,
					);
				} else {
					// Add new document
					await setDoc(slideDocRef, docData);
					console.log(
						`Added new document with ID: ${slideDocRef.id}`,
					);
				}
			}),
		);
	} catch (error) {
		console.error("Error modifying on display content collection: ", error);
	}
}

export async function deleteOnDisplayThumbnail(
	deleteThumbnail: OnDisplayThumbnail,
): Promise<void> {
	const newsSlideDocRef = doc(onDisplayCollection, deleteThumbnail.path);
	try {
		// Delete the slide from Firestore or your preferred database
		await deleteDoc(newsSlideDocRef);
		console.log("Thumbnail deleted successfully from Firestore.");
	} catch (error) {
		console.error("Error deleting thumbnail from Firestore due to:", error);
		// Handle any errors that occur during deletion
	}
}

export async function addEmail(
	newEmail: string,
	newName: string,
): Promise<string | undefined> {
	const emailCollection = collection(firestore, "Emails"); // Target the Emails collection

	// Check if the email already exists in the collection
	const querySnapshot = await getDocs(emailCollection);
	const doesEmailExist = querySnapshot.docs.some(
		(doc) => doc.data().email === newEmail,
	);

	if (doesEmailExist) {
		console.log(`Match found with: ${newEmail}`);
		return `${newEmail} has already subscribed!`;
	}

	// If the email does not exist, add it to the collection
	const newEmailDoc = doc(emailCollection); // Create a new document reference within the collection
	try {
		await setDoc(newEmailDoc, { email: newEmail, name: newName }); // Set the document with the newEmail data
		console.log(`New email added successfully: ${newName} / ${newEmail}`);
		return "Thanks for subscribing!";
	} catch (error) {
		console.log(`Error when adding new email`);
		return "Submission Failed! Please try again later or use a different email.";
	}
}
