import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const auth = getAuth();

export const isAdmin = (email: string): boolean => {
	// For demonstration, replace this with your logic
	const allowedAdminEmails = [
		"info@benveniste.com",
		"rougeboy123@live.co.uk",
		"ryancstott@gmail.com",
	];
	return allowedAdminEmails.includes(email);
};

export const signInWithGoogle = async (navigate: any): Promise<void> => {
	const provider = new GoogleAuthProvider();

	try {
		const result = await signInWithPopup(auth, provider);
		const user = result.user;

		if (user?.email !== null && isAdmin(user.email)) {
			navigate("/admin");
		} else {
			navigate("/");
		}
	} catch (error) {
		console.error("Error during sign-in:", error);
		navigate("/");
	}
};
