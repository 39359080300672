import React from "react";

export const About: React.FC = () => {
	return (
		<section className="About">
			<div className="aboutContent">
				<article className="aboutText">
					<p className="aboutStyle">
						Dan Benveniste (Copenhagen, 1966) is a fine art printer
						and print publisher with over 30 years of professional
						experience.
					</p>
					<p className="aboutStyle">
						Benveniste Contemporary was established in Madrid in
						1993. We print and publish exclusively contemporary
						intaglio printmaking. Our editions are produced in close
						collaboration with the artists, aiming to stretch the
						media to its limits and, whenever possible, beyond. We
						often publish unique and semi-unique pieces produced
						over extended periods of time.
					</p>
					<p className="aboutStyle">
						Benveniste Contemporary work with established artists
						from both Spain and abroad alongside many other upcoming
						talents. Our workshop specialises in photogravure and
						large format copper plate printmaking with paper sizes
						reaching 150 x 300 cm.
					</p>
					<p className="aboutStyle">
						Since 1999 and under the label The Print Subscribers
						Club, Benveniste Contemporary also publishes a biannual
						portfolio with a selected artist for a group of members.
						For more information about this initiative, please click
						here.
					</p>
					<p className="aboutStyle">
						Work published by Benveniste Contemporary has been
						acquired by numerous institutions including Banco de
						España, Madrid, ES; Den Kgl. Kobberstiksamling,
						Copenhagen, DK; Horsens Kunstmuseum, Horsens, DK; JP
						Morgan, New York, US; KIASMA, Helsinki, FI; Kunsthalle,
						Hamburg, DE; Museo Nacional Centro de Arte Reina Sofía,
						Madrid, ES; Museum of Modern Art, MOMA, New York, US;
						Staalische Graphische Sammlung, Munich, DE; Victoria &
						Albert Museum, London, UK; Tate Collection, London, UK;
						The Henry Moore Foundation, London, UK; The National
						Gallery of South Australia, AU; The New York Public
						Library, New York, US; The Phillips Morris Collection,
						Madrid, ES; Washington Museum of Art, Washington, US;
						Whitney Museum of American Art, New York, US.
					</p>
				</article>
				<div className="aboutImage">
					<img
						src="https://firebasestorage.googleapis.com/v0/b/benveniste-contemporary.appspot.com/o/Homepage%2FBannerImages%2Fbenveniste_taller-min.webp?alt=media&token=988e8de2-2001-4f6f-998d-27f131a206c3"
						alt="The Benvenitse Workshop"
					/>
				</div>
			</div>
		</section>
	);
};
