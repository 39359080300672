import { useEffect, useState } from "react";
import Carousel from "../Carousel/Carousel";
import Slide from "../Carousel/SetSlide";
import type { Piece } from "../Carousel/SetSlide";
import type { Artist, Set } from "./interfaces";
import ErrorPage from "../errorPage";
import { useParams, useLocation } from "react-router-dom";
import { readSingleArtist } from "./FirestoreDB";
import {
	extractCapitalLettersAndNormalise,
	normaliseString,
	textFormatter,
} from "./utilities";
import smoothscroll from "smoothscroll-polyfill";

// Polyfill for multibrowser comp.
smoothscroll.polyfill();

const DisplayArtistData: React.FC = () => {
	const location = useLocation();
	const anchor = location.hash.substring(1); // Remove the '#' from the hash
	const [artist, setArtist] = useState<Artist | undefined>(undefined);
	const [loaded, setLoaded] = useState(false);
	const artistPath = useParams().pathName;
	useEffect(() => {
		const fetchData = async (): Promise<Artist | undefined> =>
			await readSingleArtist(artistPath);
		fetchData()
			.then((artistData) => {
				setArtist(artistData);
				setLoaded(true);
				console.log("Artist Content successfully loaded!");
			})
			.catch((error) => {
				console.error("Error fetching artist content!", {
					error,
				});
			});
	}, [artistPath, anchor]);

	// useEffect(() => {
	// 	if (artist !== undefined && loaded && anchor !== undefined) {
	// 		setTimeout(() => {
	// 			const element = document.getElementById(anchor);
	// 			if (element !== null) {
	// 				element.scrollIntoView({
	// 					block: "start",
	// 					behavior: "smooth",
	// 				});
	// 			}
	// 		}, 750); // Timeout set to 1 second
	// 	}
	// }, [artist, anchor, location, loaded]);

	function easeInOutCubic(t: number): number {
		return t < 0.5
			? 4 * t * t * t
			: (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
	}

	function smoothScroll(element: HTMLElement, duration: number): void {
		const targetPosition = element.getBoundingClientRect().top;
		const startPosition = window.scrollY;
		const distance = targetPosition;
		let startTime: number | null = null;

		function animation(currentTime: number): void {
			if (startTime === null) startTime = currentTime;
			const timeElapsed = currentTime - startTime;
			const run = easeInOutCubic(timeElapsed / duration) * distance;
			window.scroll(0, startPosition + run);
			if (timeElapsed < duration) requestAnimationFrame(animation);
		}
		requestAnimationFrame(animation);
	}

	useEffect(() => {
		console.log(`Testing anchor: ${anchor}`);
		if (artist !== undefined && loaded && anchor !== undefined) {
			setTimeout(() => {
				const element = document.getElementById(anchor);
				if (element !== null) {
					smoothScroll(element, 2000); // 2000ms = 2 seconds
				}
			}, 750);
		}
	}, [artist, anchor, location, loaded]);

	if (artist === undefined) {
		return <ErrorPage></ErrorPage>;
	} else {
		const artistSets: Set[] = [...artist.sets].reverse();
		return (
			<>
				<section className="ArtistDescription">
					<div className="introArtistTitle">
						<h1 className="artistName">{artist.name}</h1>
					</div>
					<div className="artistTextContainer">
						<article className="artistText">
							<p>{textFormatter(artist.topCopy)}</p>
						</article>
					</div>
					<div className="artistLeadImageContainer">
						<img
							src={artist.topImage.src} // Artists portrait image
							alt={artist.topImage.alt}
						/>
					</div>
				</section>
				<section className="ArtistWorks">
					{artistSets.map((set: Set, index: number) => (
						<div
							key={`${index}${set.name}`}
							className="setContainer"
							id={`${extractCapitalLettersAndNormalise(
								artist.name,
							)}${normaliseString(set.name)}`}
						>
							{index < 1 && (
								<div className="borderedArtistTitle">
									<h2 className="artistName">
										{artist.name}
										<span className="setTitle">
											_{set.name}
										</span>
									</h2>
								</div>
							)}
							{index >= 1 && (
								<div className="borderedArtistTitle">
									<h2 className="artistName">
										{artist.name}
										<span className="setTitle">
											_{set.name}
										</span>
									</h2>
								</div>
							)}
							<div className="artistTextContainer">
								<article className="artistText">
									<p>{textFormatter(set.workDescription)}</p>
									<a
										href={set.pdf}
										target="_blank"
										rel="noopener noreferrer"
									>
										<button className="setPDF">
											Download dossier
										</button>
									</a>
								</article>
							</div>
							<div className="pieceContainer">
								<Carousel
									artistName={artist.name}
									setName={set.name}
								>
									{set.pieces.map((piece: Piece) => (
										<Slide
											key={
												piece.nameSuffix +
												"-" +
												piece.code
											}
											setName={set.name}
											artistName={artist.name}
											artistPath={artist.filePath}
											piece={piece}
										/>
									))}
								</Carousel>
							</div>
						</div>
					))}
				</section>
			</>
		);
	}
};

export default DisplayArtistData;
