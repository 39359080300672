import React from "react";
import {
	StringSplicer,
	extractCapitalLettersAndNormalise,
	normaliseString,
} from "../Components/utilities";

export interface Img {
	src: string;
	alt: string;
}

export interface Piece {
	nameSuffix: string;
	shortDescription: string;
	size: string;
	sizePlate: string;
	edition: string;
	pricing: string;
	code: string;
	image: Img;
}

interface SlideProps {
	artistName: string;
	setName: string;
	artistPath: string;
	piece: Piece;
}

const pieceName = (nameSuffix: string): string => {
	return StringSplicer(nameSuffix, nameSuffix.length - 6, 6, undefined);
};

const pieceDate = (nameSuffix: string): string => {
	return StringSplicer(nameSuffix, 0, nameSuffix.length - 6, undefined);
};

const enquireLink = (
	artistName: string,
	setName: string,
	artistPath: string,
): string => {
	const p2Link =
		extractCapitalLettersAndNormalise(artistName) +
		normaliseString(setName);
	return `${artistPath}#${p2Link}`;
};

const Slide: React.FC<SlideProps> = ({
	artistName,
	artistPath,
	piece,
	setName,
}) => {
	return (
		<div key={`${piece.nameSuffix}-${piece.code}`} className="SContainer">
			<div className="STextWrapper">
				<div className="artistImageCaption">
					<h2>{artistName}</h2>
					<div className="pieceNameDate">
						<h3
							style={{
								display: "inline-block",
								fontStyle: "italic",
							}}
						>
							{pieceName(piece.nameSuffix)}
						</h3>
						<h3
							style={{
								display: "inline-block",
							}}
						>
							{pieceDate(piece.nameSuffix)}
						</h3>
					</div>
					{piece.pricing[0] !== "€" && (
						<p className="pricingItalic">{piece.pricing}</p>
					)}
					{/* Makes non-number pricing's italic */}
					{piece.pricing[0] === "€" && (
						<p className="pricing">{piece.pricing}</p>
					)}
					<a
						className="enquire"
						href={`mailto:info@benveniste.com?Subject=ENQUIRY | I am interested in ${
							piece.nameSuffix
						} by ${artistName} [${
							piece.code
						}] &body=Link to piece:  %0D https://www.benveniste.com/artists/${enquireLink(
							artistName,
							setName,
							artistPath,
						)}`}
						target="_top"
					>
						ENQUIRE
					</a>

					<p>{piece.shortDescription}</p>
					<p>{piece.sizePlate}</p>
					<p>{piece.size}</p>
					<p>{piece.edition}</p>
					<p>{piece.code}</p>
				</div>
			</div>
			<div
				key={piece.nameSuffix + "ImageWrapper"}
				className="SImageWrapper"
			>
				<img
					key={piece.code}
					src={piece.image.src}
					alt={piece.image.alt}
				/>
			</div>
		</div>
	);
};

export default Slide;
