import { useEffect, useRef, useState } from "react";
import type {
	OnDisplayNewEdition,
	OnDisplayPSC,
	OnDisplayThumbnail,
	OnDisplayOther,
} from "./Components/interfaces";
import { readOnDisplayThumbnails } from "./Components/FirestoreDB";
import { Link, useLocation } from "react-router-dom";
import { normaliseString, textFormatter } from "./Components/utilities";
import type { DocumentSnapshot } from "firebase/firestore";

export default function OnDisplay(): React.ReactElement {
	const [content, setContent] = useState<OnDisplayThumbnail[] | []>([]);
	const [error, setError] = useState<string | null>(null);
	const [page, setPage] = useState<number>(0);
	const [cursor, setCursor] = useState<undefined | null | DocumentSnapshot>(
		undefined,
	);
	const [loaded, setLoaded] = useState(false);
	const { pathname } = useLocation();
	const loader = useRef(null);

	const fetchContent = async (): Promise<void> => {
		const savedPage = localStorage.getItem("page");
		let searchNu = 9;
		if (!loaded && savedPage !== null) {
			if (JSON.parse(savedPage) > page) {
				const newPage: number = JSON.parse(savedPage);
				setPage(newPage);
				searchNu = searchNu * newPage;
			}
		}
		const result = await readOnDisplayThumbnails(searchNu, cursor);
		if (result.thumbnails.length > 0) {
			setContent((prevContent) => [...prevContent, ...result.thumbnails]);
			setCursor(result.cursor);
			setLoaded(true);
			console.log("Thumbnails successfully loaded");
		}
	};

	const restoreScrollPosition = (): void => {
		const savedScrollPosition = localStorage.getItem("scrollPosition");
		if (savedScrollPosition !== null && content.length > 0) {
			console.log("Restoring scroll position...");
			window.scrollTo(0, parseInt(savedScrollPosition));
		}
	};

	const handleObserver = (entities: IntersectionObserverEntry[]): void => {
		const target = entities[0];
		if (target.isIntersecting && loaded) {
			setPage((prevPage) => {
				const newPage = prevPage + 1;
				return newPage;
			});
		}
	};

	const fetched = useRef(false);

	useEffect(() => {
		if (!fetched.current) {
			console.log("Ran");
			fetchContent().catch((error) => {
				console.error("Error fetching thumbnails...!", { error });
				setError("Failed to load the content. Please try again later.");
			});
			fetched.current = true;
		}
	}, []);

	useEffect(() => {
		if (loaded) {
			// Only restore the scroll position if the content has been loaded
			if (
				localStorage.getItem("scrollPosition") !== "0" &&
				localStorage.getItem("scrollPosition") !== null
			) {
				restoreScrollPosition();
			}
		}
	}, [loaded]);

	useEffect(() => {
		if (loaded) {
			fetchContent().catch((error) => {
				console.error("Error fetching thumbnails...!", { error });
				setError("Failed to load the content. Please try again later.");
			});
			localStorage.setItem("page", JSON.stringify(page));
		}
	}, [page]);

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: "100px",
			threshold: 1.0,
		};

		const observer = new IntersectionObserver(handleObserver, options);
		if (loader.current !== null) {
			observer.observe(loader.current);
		}
		return () => {
			observer.disconnect();
		};
	}, [content, loader.current]);

	useEffect(() => {
		const handleBeforeUnload = (): void => {
			localStorage.setItem("scrollPosition", "0");
			localStorage.setItem("page", "0");
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		// Remove event listener on cleanup
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		let scrollPosition = localStorage.getItem("scrollPosition");
		const handleScroll = (): void => {
			// Update scroll position when user scrolls
			scrollPosition = window.scrollY.toString();
		};
		window.addEventListener("scroll", handleScroll);
		// Remove event listener on cleanup
		return () => {
			// Save scroll position when component unmounts
			window.removeEventListener("scroll", handleScroll);
			localStorage.setItem("scrollPosition", scrollPosition ?? "0");
		};
	}, []);

	if (error !== null) {
		return <div className="error">{error}</div>;
	}

	if (content === undefined) {
		console.log("Loading On Display content...");
		return <div id="LoadingBox" className="loading"></div>;
	} else {
		let currentPageStyle;
		let toValue: string;
		let anchorValue: string | undefined;
		let title: string | undefined;
		return (
			<section className="onDisplay">
				{content.map((ODThumbnail, index) => {
					if (ODThumbnail.type === "PSC") {
						currentPageStyle =
							ODThumbnail.pageStyle as OnDisplayPSC;
						toValue = currentPageStyle.PSC_URL;

						return (
							<a
								key={`${ODThumbnail.path}:${index}`}
								href={toValue}
								target="_blank"
								rel="noreferrer"
								className="onDisplayBox"
							>
								<h2>The Print Subscribers Club</h2>
								<img
									src={ODThumbnail.imgSrc}
									alt={ODThumbnail.imgAlt}
								/>
								<article className="onDisplayBoxText">
									<h3>{ODThumbnail.heading1}</h3>
									<h4>_{ODThumbnail.heading2}</h4>
									<p>{ODThumbnail.description}</p>
								</article>
							</a>
						);
					} else if (ODThumbnail.type === "NewEdition") {
						currentPageStyle =
							ODThumbnail.pageStyle as OnDisplayNewEdition;
						anchorValue = currentPageStyle.artLink;
						toValue =
							anchorValue !== ""
								? `../artists/${normaliseString(ODThumbnail.heading1)}#${anchorValue}`
								: `../artists/${normaliseString(ODThumbnail.heading1)}`;
					} else if (ODThumbnail.type === "Other") {
						currentPageStyle =
							ODThumbnail.pageStyle as OnDisplayOther;
						title = currentPageStyle.title;
						toValue = `${pathname}/${ODThumbnail.path}`;
					} else {
						toValue = `${pathname}/${ODThumbnail.path}`;
					}
					return (
						<Link
							key={`${ODThumbnail.path}:${index}`}
							to={toValue}
							state={{ anchor: anchorValue }}
							className="onDisplayBox"
						>
							{ODThumbnail.type !== "NewEdition" &&
								ODThumbnail.type !== "Other" && (
									<h2>{ODThumbnail.type}</h2>
								)}
							{ODThumbnail.type === "NewEdition" && (
								<h2>New Edition</h2>
							)}
							{ODThumbnail.type === "Other" && <h2>{title}</h2>}
							<img
								src={ODThumbnail.imgSrc}
								alt={ODThumbnail.imgAlt}
							/>
							<article className="onDisplayBoxText">
								<h3>{ODThumbnail.heading1}</h3>
								<h4>_{ODThumbnail.heading2}</h4>
								<p>{textFormatter(ODThumbnail.description)}</p>
							</article>
						</Link>
					);
				})}
				<div className="infScrollBox" ref={loader}></div>
			</section>
		);
	}
}
