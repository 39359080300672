import React, { useState } from "react";
import type { ReactElement } from "react";
import { addEmail } from "./FirestoreDB";
// import { Email } from "./interfaces";

const SubscribePopup: React.FC = () => {
	const [showPopup, setShowPopup] = useState(false);
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [response, setResponse] = useState<undefined | ReactElement>(
		undefined,
	);

	const handleClick = (): void => {
		setShowPopup(true);
	};

	const handleEmailChange = (
		e: React.ChangeEvent<HTMLInputElement>,
	): void => {
		setEmail(e.target.value);
	};

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setName(e.target.value);
	};

	const handleSubmit = async (): Promise<void> => {
		// Define the regex for email validation
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		const nameRegex = /^[a-zA-Z]+( [a-zA-Z]+)*$/;

		// Validate the email format
		if (!emailRegex.test(email)) {
			console.error("Error | Failed Regex formatting test");
			setResponse(
				<p className="errorMessage">
					Invalid email format | Please try again...
				</p>,
			);
			return;
			// Validate the name format
		} else if (!nameRegex.test(name)) {
			console.error("Error | Failed Regex formatting test");
			setResponse(
				<p className="errorMessage">
					Invalid name format | Please try again...
				</p>,
			);
			return;
		}

		const result = await addEmail(email.toLowerCase(), name.toLowerCase());
		if (result === "Thanks for subscribing!") {
			setResponse(<p className="alertMessage">{result}</p>);
			setEmail("");
			setName("");
			setTimeout(() => {
				setResponse(undefined);
				setShowPopup(false);
			}, 3000);
		} else {
			setResponse(<p className="errorMessage">{result}</p>);
		}
	};

	return (
		<>
			<p className="subLink" onClick={handleClick}>
				Subscribe
			</p>
			{showPopup && (
				<div className="subscribePopUpOverlay">
					<div className="subscribePopUpBox">
						<p>
							Enter your name & email below to subscribe for
							updates:
						</p>
						<label>
							<input
								type="name"
								placeholder="Enter Name"
								maxLength={20}
								minLength={2}
								value={name}
								onChange={handleNameChange}
							/>
						</label>
						<label>
							<input
								type="email"
								placeholder="Enter Email"
								value={email}
								maxLength={30}
								onChange={handleEmailChange}
							/>
						</label>
						{response !== undefined && response}
						<div className="buttonBox">
							<button
								onClick={() => {
									handleSubmit();
								}}
							>
								Subscribe
							</button>
							<button
								onClick={() => {
									setShowPopup(!showPopup);
								}}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SubscribePopup;
