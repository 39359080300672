import React from "react";

const ErrorPage: React.FC = () => {
	return (
		<section id="Loading" style={{ height: "100vh", width: "100vw" }}>
			<h1>Loading...</h1>
		</section>
	);
};

export default ErrorPage;
