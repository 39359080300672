export function normaliseString(inputString: string): string {
	// Convert the string to lowercase and normalize any accents
	const normalizedStr = inputString.toLowerCase().normalize("NFD");

	// Remove any accents and specified special characters from the string using a regular expression
	// The regex includes:
	// - Diacritic marks: \u0300-\u036f
	// - Special characters: \s (spaces), \\ (backslash), \/ (forward slash), |, +, =, -, _, £, $, #, %, (, ), &, , (comma), . (period)
	const regex = /[\u0300-\u036f\s\\/|+=\-_£$#%().,&]/g;
	const cleanedStr = normalizedStr.replace(regex, "");

	// Return the cleaned string without the special characters
	return cleanedStr;
}

export function extractCapitalLettersAndNormalise(str: string): string {
	let result = "";
	for (let i = 0; i < str.length; i++) {
		const char = str.charAt(i);
		// Convert accented letters to their equivalent normal form
		const normalisedChar = char
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "");
		if (
			normalisedChar.toUpperCase() === normalisedChar &&
			normalisedChar.toLowerCase() !== normalisedChar
		) {
			result += normalisedChar;
		}
	}
	return result;
}

export function StringSplicer(
	str: string,
	index: number,
	count: number,
	add: string | number | undefined,
): string {
	// This function allows the splicing of a string like an array
	if (index < 0) {
		index = str.length + index;
		if (index < 0) {
			index = 0;
		}
	}

	// Convert add to a string if it's a number, otherwise use an empty string
	const addString =
		add === undefined ? "" : typeof add === "number" ? String(add) : add;

	// Include the comma and space characters if add is a string
	const validAdd = typeof add === "string" ? ", " + addString : addString;

	// Ensure addString is not 'NaN' (happens when add is not a valid number)
	const finalAdd = validAdd === "NaN" ? "" : validAdd;

	return str.slice(0, index) + finalAdd + str.slice(index + count);
}

export function textFormatter(text: string): React.ReactElement {
	const splitText = text
		.split(
			/(<bold>.*?<\/bold>)|(<em>.*?<\/em>)|(<br\s*\/?>)|(&lt;bold&gt;.*?&lt;\/bold&gt;)|(&lt;em&gt;.*?&lt;\/em&gt;)|(&lt;br\s*\/?&gt;)/,
		)
		.filter((segment) => segment !== undefined && segment !== "");

	return (
		<>
			{splitText.map((segment, index) => {
				// Check for bold tags
				if (
					segment.startsWith("<bold>") ||
					segment.startsWith("&lt;bold&gt;")
				) {
					const innerText = segment.replace(
						/(<bold>|<\/bold>|&lt;bold&gt;|&lt;\/bold&gt;)/g,
						"",
					);
					return (
						<span className="textFormatterBold" key={index}>
							{innerText}
						</span>
					);
				}

				// Check for italic tags
				if (
					segment.startsWith("<em>") ||
					segment.startsWith("&lt;em&gt;")
				) {
					const innerText = segment.replace(
						/(<em>|<\/em>|&lt;em&gt;|&lt;\/em&gt;)/g,
						"",
					);
					return (
						<span className="textFormatterItalic" key={index}>
							{innerText}
						</span>
					);
				}

				// Check for line break tags
				if (segment.match(/<br\s*\/?>|&lt;br\s*\/?&gt;/) !== null) {
					return <br key={index} />;
				}

				// Return normal text
				return segment;
			})}
		</>
	);
}

export const capitaliseFirstName = (fullName: string): string => {
	// Split the full name into an array of names
	const names = fullName.split(" ");

	// Get the first name
	const firstName = names[0];

	// Capitalize the first letter of the first name
	const capitalisedFirstName =
		firstName.charAt(0).toUpperCase() + firstName.slice(1);

	return capitalisedFirstName;
};
