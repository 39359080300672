import React from "react";
import { Link, useLocation } from "react-router-dom";
import SubscribePopup from "./Components/subscribePopUp";

export const Footer: React.FC = () => {
	const location = useLocation();
	const [page, setPage] = React.useState("homepage");

	// This function will smoothly scroll the page to the top
	const scrollToTop = (): void => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	};

	return (
		<footer id="Info">
			<div className="left">
				<Link
					className="contactLink"
					onClick={(event) => {
						setPage("contact");
					}}
					to="/contact"
				>
					<h1>Stay in touch</h1>
				</Link>
				<nav>
					<ul>
						<li>
							<Link
								className={
									page === "about" ||
									location.pathname === "/about"
										? "active"
										: "unactive"
								}
								onClick={(event) => {
									setPage("about");
								}}
								to="/about"
							>
								About
							</Link>
						</li>
						<li>
							<a
								href="https://instagram.com/benveniste_contemporary?igshid=NTc4MTIwNjQ2YQ=="
								target="_blank"
								rel="noopener noreferrer"
							>
								News
							</a>
						</li>
						<li>
							<SubscribePopup></SubscribePopup>
							{/* <a
								href="https://theprintsubscribersclub.com/suscripcion/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Subscribe
							</a> */}
						</li>
						<li>
							<Link
								className={
									page === "ppc" ||
									location.pathname === "/ppc"
										? "active"
										: "unactive"
								}
								onClick={(event) => {
									setPage("ppc");
								}}
								to="/ppc"
							>
								Privacy Policy & Cookies
							</Link>
						</li>
						<li>
							{/* "Back to Top" Button */}
							<a onClick={scrollToTop}>Back to Top</a>
						</li>
					</ul>
				</nav>
			</div>
			<div className="right">
				<div className="allRightsReserved">
					<p>© Benveniste Contemporary – All Rights Reserved.</p>
				</div>
			</div>
		</footer>
	);
};
