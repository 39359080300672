import * as React from "react";
import styled, { css } from "styled-components";
import type { Artist, Set } from "../Components/interfaces";

const SCarouselWrapper = styled.div`
	position: relative;
	// left: -2.6vw;
	display: inline-block;
	width: 100%;
`;

interface ICarouselSlide {
	$active: string;
}

const SCarouselSlide = styled.div<ICarouselSlide>`
	flex: 0 0 auto;
	opacity: ${(props) => (props.$active === "true" ? 1 : 0)};
	transition: all 0.75s ease-in-out;
	width: 100%;
`;

interface ICarouselProps {
	$currentslide: number;
}

const SCarouselSlides = styled.div<ICarouselProps>`
	display: flex;
	${(props) =>
		props.$currentslide !== undefined &&
		css`
			transform: translateX(-${props.$currentslide * 100}%);
		`};
	transition: all 1.75s ease-in-out;
`;

interface IProps {
	children: JSX.Element[];
	artistName: Artist["name"];
	setName: Set["name"];
}

const Carousel: React.FC<IProps> = ({ children, artistName, setName }) => {
	const [currentSlide, setCurrentSlide] = React.useState(0);
	const [slideDirection, setSlideDirection] = React.useState("next"); // 'left' or 'right'
	const touchStartX = React.useRef<number | null>(null);
	const touchStartY = React.useRef<number | null>(null);
	const touchEndX = React.useRef<number | null>(null);
	const touchEndY = React.useRef<number | null>(null);
	const carouselRef = React.useRef<HTMLDivElement | null>(null);
	const minSwipeDistance = 125;
	const YscrollTolerance = 500;

	const preventDefault = (e: TouchEvent): void => {
		if (e.touches.length < 2) {
			e.preventDefault();
		}
	};

	React.useEffect(() => {
		// Add a touchend event listener that re-adds the touchmove event listener
		const handleTouchEnd = (): void => {
			if (carouselRef.current !== null) {
				carouselRef.current.removeEventListener(
					"touchmove",
					preventDefault,
				);
			}
		};
		carouselRef.current?.addEventListener("touchend", handleTouchEnd);

		return () => {
			// Remove the event listeners when the component is unmounted
			carouselRef.current?.removeEventListener(
				"touchmove",
				preventDefault,
			);
			carouselRef.current?.removeEventListener(
				"touchend",
				handleTouchEnd,
			);
		};
	}, []);

	const navigateToSlide = (direction: "prev" | "next"): void => {
		setSlideDirection(direction);
		setCurrentSlide((prev) =>
			direction === "next"
				? (prev + 1) % children.length
				: (prev - 1 + children.length) % children.length,
		);
	};

	const handleTouchStart = (e: React.TouchEvent): void => {
		if (e.targetTouches.length < 2) {
			touchEndX.current = null; // Reset touchEnd
			touchEndY.current = null; // Reset touchEnd
			touchStartX.current = e.touches[0].clientX;
			touchStartY.current = e.touches[0].clientY;
			if (carouselRef.current !== null) {
				carouselRef.current.addEventListener(
					"touchmove",
					preventDefault,
				);
			}
		}
	};

	const handleTouchMove = (e: React.TouchEvent): void => {
		if (
			e.touches.length < 2 &&
			touchStartX.current !== null &&
			touchStartY.current !== null
		) {
			touchEndX.current = e.touches[0].clientX;
			touchEndY.current = e.touches[0].clientY;
			const isUpSwipe =
				touchEndY.current - touchStartY.current > YscrollTolerance;
			const isDownSwipe =
				touchEndY.current - touchStartY.current < -YscrollTolerance;
			if (isDownSwipe || isUpSwipe) {
				if (carouselRef.current !== null) {
					carouselRef.current.removeEventListener(
						"touchmove",
						preventDefault,
					);
				}
			}
		}
	};

	const handleTouchEnd = (): void => {
		if (
			touchStartX.current === null ||
			touchEndX.current === null ||
			touchStartY.current === null ||
			touchEndY.current === null
		) {
			return;
		}
		const distanceX = touchEndX.current - touchStartX.current;
		const isLeftSwipe = distanceX < -minSwipeDistance;
		const isRightSwipe = distanceX > minSwipeDistance;
		if (isLeftSwipe) {
			navigateToSlide("next");
		} else if (isRightSwipe) {
			navigateToSlide("prev");
		} // User swiped up, unlock the scroll
		if (carouselRef.current !== null) {
			carouselRef.current.removeEventListener(
				"touchmove",
				preventDefault,
			);
		}
	};

	return (
		<>
			<div
				ref={carouselRef}
				className="CarouselContainer"
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}
			>
				<SCarouselWrapper>
					<SCarouselSlides $currentslide={currentSlide}>
						{children.map((slide, index) => (
							<SCarouselSlide
								$active={
									currentSlide === index ? "true" : "false"
								}
								key={index}
							>
								{slide}
							</SCarouselSlide>
						))}
					</SCarouselSlides>
				</SCarouselWrapper>
			</div>
			<nav className="carouselNav">
				<button
					className="LeftSlideButton"
					onClick={(): void => {
						navigateToSlide("prev");
					}}
					aria-label="Previous Slide"
				>
					{`<`}
				</button>
				{children.map((_, index) => (
					<button
						key={`${artistName}${setName}${index}`}
						className={
							currentSlide === index
								? `activeCarouselNavButton${
										slideDirection === "prev"
											? "Left"
											: "Right"
									}`
								: `carouselNavButton${
										slideDirection === "prev"
											? "Left"
											: "Right"
									}`
						}
						onClick={() => {
							setCurrentSlide(index);
						}}
					></button>
				))}
				<button
					className="RightSlideButton"
					onClick={(): void => {
						navigateToSlide("next");
					}}
					aria-label="Next Slide"
				>
					{`>`}
				</button>
			</nav>
		</>
	);
};

export default Carousel;
