import React, { useEffect, useState } from "react";
import { type Artist, type Piece, type Set } from "./interfaces";
import {
	normaliseString,
	extractCapitalLettersAndNormalise,
} from "./utilities";
import {
	type FieldValue,
	serverTimestamp,
	Timestamp,
} from "firebase/firestore";

interface InputFormProps {
	onSubmit: (formData: Artist) => void;
	selectedArtist: Artist | undefined | null;
}

const InputFormArtists: React.FC<InputFormProps> = ({
	onSubmit,
	selectedArtist,
}) => {
	const [inputName, setInputName] = useState("");
	const [inputLeadName, setInputLeadName] = useState("");
	const [inputLeadSrc, setInputLeadSrc] = useState("");
	const [inputTopCopy, setInputTopCopy] = useState("");
	const [inputTopImageSrc, setInputTopImageSrc] = useState("");
	const [inputSets, setInputSets] = useState<Set[]>([]);
	const [expandedSets, setExpandedSets] = useState<boolean[]>([]);
	const [artistCreatedAt, setArtistCreatedAt] = useState<
		string | null | FieldValue
	>(null);
	const [userMessage, setUserMessage] = useState<string | undefined>(
		undefined,
	);

	useEffect(() => {
		if (selectedArtist !== null && selectedArtist !== undefined) {
			console.log(selectedArtist);
			setInputName(selectedArtist.name);
			setInputLeadName(selectedArtist.lead.name);
			setInputLeadSrc(selectedArtist.lead.src);
			setInputTopCopy(selectedArtist.topCopy);
			setInputTopImageSrc(selectedArtist.topImage.src);
			setInputSets(selectedArtist.sets);
			// Handle createdAt conversion here
			// When selectedArtist changes, check if createdAt is a Timestamp
			if (selectedArtist.createdAt instanceof Timestamp) {
				setArtistCreatedAt(
					selectedArtist.createdAt.toDate().toISOString(),
				);
			} else if (typeof selectedArtist.createdAt === "string") {
				setArtistCreatedAt(selectedArtist.createdAt);
			} else {
				// If it's not a Timestamp or string, handle as a null or undefined
				setArtistCreatedAt(null);
			}
		} else {
			setInputName("");
			setInputLeadName("");
			setInputLeadSrc("");
			setInputTopCopy("");
			setInputTopImageSrc("");
			setInputSets([]);
			setArtistCreatedAt(null);
		}
	}, [selectedArtist]);

	const handleSubmit = (e: React.FormEvent): void => {
		e.preventDefault();
		setInputSets((sets: Set[]) =>
			sets.map((set: Set) => ({
				...set,
				pieces: set.pieces.map((piece: Piece) => ({
					...piece,
					image: {
						...piece.image,
						alt: piece.nameSuffix,
					},
				})),
				numOfPieces: set.pieces.length,
			})),
		);

		const newArtist: Artist = {
			name: inputName,
			filePath: normaliseString(inputName),
			inId: extractCapitalLettersAndNormalise(inputName),
			createdAt: artistCreatedAt ?? serverTimestamp(),
			lead: {
				name: inputLeadName,
				src: inputLeadSrc,
			},
			topCopy: inputTopCopy,
			topImage: {
				src: inputTopImageSrc,
				alt: `Portrait of ${inputName}`,
			},
			sets: inputSets,
		};
		if (
			window.confirm("Confirm submit ? (Refresh page to reset the form)")
		) {
			onSubmit(newArtist);
		}
	};

	const handleToggleExpand = (setIndex: number): void => {
		setExpandedSets((prevState) => {
			const newState = [...prevState];
			newState[setIndex] = !newState[setIndex];
			return newState;
		});
	};

	const handleResetArtistPosistion = (): void => {
		if (
			window.confirm(
				"Are you sure you want to move the artist to the top ? (Irreversible)",
			)
		) {
			setArtistCreatedAt(null);
			setUserMessage("Artist position reset!");
			setTimeout(() => {
				setUserMessage(undefined);
			}, 5000);
		}
	};

	const handleAddSet = (): void => {
		setInputSets((sets) => [
			...sets,
			{
				name: "",
				numOfPieces: 1,
				workDescription: "",
				pdf: "",
				pieces: [
					{
						nameSuffix: "",
						shortDescription: "",
						sizePlate: "",
						size: "",
						edition: "",
						pricing: "",
						code: "",
						image: {
							src: "",
							alt: "",
						},
					},
				],
			},
		]);
	};

	const handleRemoveSet = (setIndex: number): void => {
		if (
			window.confirm(
				"Are you sure you want to delete this set? (Deletion finalised on form submission)",
			)
		) {
			setInputSets((sets) => {
				const newSets = [...sets];
				newSets.splice(setIndex, 1);
				return newSets;
			});
		}
	};

	const handleMoveSet = (setIndex: number, moveUp: boolean): void => {
		setInputSets((sets) => {
			const newSets = [...sets];
			if (moveUp) {
				if (setIndex > 0) {
					// swap the current set with the one above it
					[newSets[setIndex - 1], newSets[setIndex]] = [
						newSets[setIndex],
						newSets[setIndex - 1],
					];
				}
			} else {
				if (setIndex < newSets.length - 1) {
					// swap the current set with the one below it
					[newSets[setIndex], newSets[setIndex + 1]] = [
						newSets[setIndex + 1],
						newSets[setIndex],
					];
				}
			}
			return newSets;
		});
	};

	const handleAddPiece = (setIndex: number, pieceIndex: number): void => {
		setInputSets((sets) => {
			const newSets = [...sets];
			const newPieces = [...newSets[setIndex].pieces];
			const newIndex = pieceIndex + 1;
			if (
				newIndex <= newPieces.length &&
				newIndex >= 0 &&
				/* 
				This function is very hard to read so I can't fix this error, you might have to look at this one yourself.
				You seem to be checking that there isn't anything in the new index, but you're splicing so you do sometimes
				expect pieces to be there? Otherwise you'd just be appending and wouldn't need a complex function
				*/
				// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
				!newPieces[newIndex]
			) {
				newPieces.splice(newIndex, 0, {
					nameSuffix: "",
					shortDescription: "",
					sizePlate: "",
					size: "",
					edition: "",
					pricing: "",
					code: "",
					image: {
						src: "",
						alt: "",
					},
				});
				newSets[setIndex].pieces = newPieces;
			}
			return newSets;
		});
	};

	const handleMovePiece = (
		setIndex: number,
		pieceIndex: number,
		moveUp: boolean,
	): void => {
		setInputSets((sets) => {
			const newSets = [...sets];
			const currentSet = { ...newSets[setIndex] };
			const newPieces = [...currentSet.pieces];
			if (moveUp) {
				if (pieceIndex > 0) {
					// swap the current set with the one above it
					[newPieces[pieceIndex - 1], newPieces[pieceIndex]] = [
						newPieces[pieceIndex],
						newPieces[pieceIndex - 1],
					];
				}
			} else {
				if (pieceIndex + 1 < newPieces.length) {
					// swap the current set with the one below it
					[newPieces[pieceIndex], newPieces[pieceIndex + 1]] = [
						newPieces[pieceIndex + 1],
						newPieces[pieceIndex],
					];
				}
			}
			currentSet.pieces = newPieces;
			newSets[setIndex] = currentSet;
			return newSets;
		});
	};

	const handleRemovePiece = (setIndex: number, pieceIndex: number): void => {
		if (
			window.confirm(
				"Are you sure you want to delete this piece? (Deletion finalised on form submission)",
			)
		) {
			setInputSets((sets) => {
				const newSets = [...sets];
				const currentSet = { ...newSets[setIndex] };
				const newPieces = [...currentSet.pieces];
				// check if the pieceIndex is valid
				if (pieceIndex >= 0 && pieceIndex < newPieces.length) {
					// remove the piece at the specified index
					newPieces.splice(pieceIndex, 1);
					// update the pieces array of the corresponding set
					currentSet.pieces = newPieces;
					newSets[setIndex] = currentSet;
				}
				return newSets;
			});
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<h2>Add a New Artist</h2>
			<label>
				New Artist Name:
				<input
					type="text"
					value={inputName}
					onChange={(e) => {
						setInputName(e.target.value);
					}}
				/>
			</label>
			<br />
			<label>
				Name of the Lead Image:
				<input
					type="text"
					value={inputLeadName}
					onChange={(e) => {
						setInputLeadName(e.target.value);
					}}
				/>
			</label>
			<br />
			<label>
				URL to Input Lead Image (Access token from the firebase storage)
				<input
					type="text"
					value={inputLeadSrc}
					onChange={(e) => {
						setInputLeadSrc(e.target.value);
					}}
				/>
			</label>
			<br />
			<label>
				The Artist's Description
				<br></br>
				<em>
					Available Styles = Italic: &lt;em&gt;&lt;/em&gt; | Bold:
					&lt;bold&gt;&lt;/bold&gt; | Break: &lt;br /&gt;
				</em>
				<em>
					Note: Use "&lt;em&gt;...&lt;/em&gt;" for quotes and don't
					foret to close tags at every paragraph
				</em>
				<textarea
					placeholder="Enter Artist Description here"
					/* maxLength={10} */ className="descriptionPage"
					value={inputTopCopy}
					onChange={(e) => {
						setInputTopCopy(e.target.value);
					}}
				/>
			</label>
			<br />
			<label>
				URL to Portrait of Artist (Access token from the firebase
				storage)
				<input
					type="text"
					value={inputTopImageSrc}
					onChange={(e) => {
						setInputTopImageSrc(e.target.value);
					}}
				/>
			</label>
			<br />
			{/* This is where the sets start */}
			{inputSets.map((set, setIndex) => (
				<React.Fragment key={`Set${setIndex}${inputName}`}>
					<div
						style={{ cursor: "pointer" }}
						onClick={() => {
							handleToggleExpand(setIndex);
						}}
					>
						<h3>
							Set {setIndex + 1}{" "}
							{expandedSets[setIndex] ? "↓" : "↑"}
						</h3>
					</div>
					{expandedSets[setIndex] && (
						<div>
							<label>
								Set Name:
								<input
									type="text"
									value={set.name}
									onChange={(e) => {
										setInputSets((sets) => {
											const newSets = [...sets];
											newSets[setIndex].name =
												e.target.value;
											return newSets;
										});
									}}
								/>
							</label>
							<br />
							<label>
								Set Description
								<br></br>
								<em>
									Available Styles = Italic:
									&lt;em&gt;&lt;/em&gt; | Bold:
									&lt;bold&gt;&lt;/bold&gt; | Break: &lt;br
									/&gt;
								</em>
								<em>
									Note: Use "&lt;em&gt;...&lt;/em&gt;" for
									quotes and don't foret to close tags at
									every paragraph
								</em>
								<textarea
									placeholder="Enter new set description..."
									/* maxLength={10} */ className="descriptionPage"
									value={set.workDescription}
									onChange={(e) => {
										setInputSets((sets) => {
											const newSets = [...sets];
											newSets[setIndex].workDescription =
												e.target.value;
											return newSets;
										});
									}}
								/>
							</label>
							<br />
							<label>
								URL to PDF of set (Access token from the
								firebase storage)
								<input
									type="text"
									value={set.pdf}
									onChange={(e) => {
										setInputSets((sets) => {
											const newSets = [...sets];
											newSets[setIndex].pdf =
												e.target.value;
											return newSets;
										});
									}}
								/>
							</label>
							<br />
							{/* This is where the pieces start */}
							{set.pieces.map((piece, pieceIndex) => (
								<div
									key={`${setIndex}${pieceIndex}${piece.code}`}
								>
									<h3 style={{ textDecoration: "underline" }}>
										Piece {pieceIndex + 1}
									</h3>
									<label>
										Name of piece
										<input
											type="text"
											value={piece.nameSuffix}
											onChange={(e) => {
												setInputSets((sets) => {
													const newSets = [...sets];
													newSets[setIndex].pieces[
														pieceIndex
													].nameSuffix =
														e.target.value;
													return newSets;
												});
											}}
										/>
									</label>
									<br />
									<label>
										Short description of the piece
										<input
											type="text"
											value={piece.shortDescription}
											onChange={(e) => {
												setInputSets((sets) => {
													const newSets = [...sets];
													newSets[setIndex].pieces[
														pieceIndex
													].shortDescription =
														e.target.value;
													return newSets;
												});
											}}
										/>
									</label>
									<br />
									<label>
										Size of the plate for this piece
										<input
											type="text"
											value={piece.sizePlate}
											onChange={(e) => {
												setInputSets((sets) => {
													const newSets = [...sets];
													newSets[setIndex].pieces[
														pieceIndex
													].sizePlate =
														e.target.value;
													return newSets;
												});
											}}
										/>
									</label>
									<br />
									<label>
										Size of the piece
										<input
											type="text"
											value={piece.size}
											onChange={(e) => {
												setInputSets((sets) => {
													const newSets = [...sets];
													newSets[setIndex].pieces[
														pieceIndex
													].size = e.target.value;
													return newSets;
												});
											}}
										/>
									</label>
									<br />
									<label>
										Piece Edition
										<input
											type="text"
											value={piece.edition}
											onChange={(e) => {
												setInputSets((sets) => {
													const newSets = [...sets];
													newSets[setIndex].pieces[
														pieceIndex
													].edition = e.target.value;
													return newSets;
												});
											}}
										/>
									</label>
									<br />
									<label>
										Piece Code
										<input
											type="text"
											value={piece.code}
											onChange={(e) => {
												setInputSets((sets) => {
													const newSets = [...sets];
													newSets[setIndex].pieces[
														pieceIndex
													].code = e.target.value;
													return newSets;
												});
											}}
										/>
									</label>
									<br />
									<label>
										Piece Price
										<input
											type="text"
											value={piece.pricing}
											onChange={(e) => {
												setInputSets((sets) => {
													const newSets = [...sets];
													newSets[setIndex].pieces[
														pieceIndex
													].pricing = e.target.value;
													return newSets;
												});
											}}
										/>
									</label>
									<br />
									<label>
										URL to Piece (Access token from the
										firebase storage)
										<input
											type="text"
											value={piece.image.src}
											onChange={(e) => {
												setInputSets((sets) => {
													const newSets = [...sets];
													newSets[setIndex].pieces[
														pieceIndex
													].image.src =
														e.target.value;
													return newSets;
												});
											}}
										/>
									</label>
									<br />
									<button
										type="button"
										onClick={() => {
											handleMovePiece(
												setIndex,
												pieceIndex,
												true,
											);
										}}
									>
										Move Piece Up
									</button>
									<button
										type="button"
										onClick={() => {
											handleMovePiece(
												setIndex,
												pieceIndex,
												false,
											);
										}}
									>
										Move Piece Down
									</button>
									<button
										type="button"
										onClick={() => {
											handleRemovePiece(
												setIndex,
												pieceIndex,
											);
										}}
									>
										Remove Piece
									</button>
								</div>
							))}
							<button
								type="button"
								onClick={() => {
									handleAddPiece(
										setIndex,
										set.pieces.length - 1,
									);
								}}
							>
								Piece +
							</button>
							<button
								type="button"
								onClick={() => {
									handleRemoveSet(setIndex);
								}}
							>
								Remove Set
							</button>
							<button
								type="button"
								onClick={() => {
									handleMoveSet(setIndex, true);
								}}
							>
								Move Set Up
							</button>
							<button
								type="button"
								onClick={() => {
									handleMoveSet(setIndex, false);
								}}
							>
								Move Set down
							</button>
						</div>
					)}
				</React.Fragment>
			))}
			<button type="button" onClick={handleAddSet}>
				{" "}
				Add New Set{" "}
			</button>
			<br></br>
			<button
				type="button"
				onClick={() => {
					handleResetArtistPosistion();
				}}
			>
				Reset Position in List
			</button>
			<button type="submit">Submit</button>
			{/* <br></br> */}
			{userMessage === "Artist position reset!" && (
				<p className="successMessage">{userMessage}</p>
			)}
		</form>
	);
};

export default InputFormArtists;
