import { useRoutes } from "react-router-dom";
import OnDisplay from "./OnDisplay";
import NewsPage from "./News";
import DisplayODContent from "./Components/DisplayODContent";
import { ArtistPage } from "./artists/ArtistPage";
import { About } from "./About";
import PrivacyPage from "./PrivacyCookiePolicy";
import { ArtistsHomePage } from "./artists/ArtistsHomePage";
import { Footer } from "./Footer";
import { HomePage } from "./HomePage";
import AdminAccess from "./Components/AdminAccess";
import Contact from "./Contact";

const RoutesComponent: React.FC = () => {
	const element = useRoutes([
		{ path: "/", element: <HomePage /> },
		{ path: "/artists/", element: <ArtistsHomePage /> },
		{ path: "/artists/:pathName", element: <ArtistPage /> },
		{ path: "/ondisplay", element: <OnDisplay /> },
		{ path: "/ondisplay/:pathName", element: <DisplayODContent /> },
		{ path: "/news", element: <NewsPage /> },
		{ path: "/about", element: <About /> },
		{ path: "/ppc", element: <PrivacyPage /> },
		{ path: "/contact", element: <Contact /> },
		{ path: "/admin", element: <AdminAccess /> },
	]);

	return (
		<>
			{element}
			{window.location.pathname !== "/contact" &&
				window.location.pathname !== "/admin" && <Footer />}
		</>
	);
};

export default RoutesComponent;
