import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import type { Location } from "react-router-dom";
import { readBannerStates } from "./Components/FirestoreDB";
import type { bannerState } from "./Components/interfaces";

interface NavListProps {
	location: Location;
	LightMode: boolean;
	setIsMenuOpen: (isMenuOpen: boolean) => void;
}

const NavList: React.FC<NavListProps> = ({
	location,
	LightMode,
	setIsMenuOpen,
}) => (
	<ul className={LightMode ? "DB" : "LB"}>
		<li>
			<Link
				className={location.pathname === "/" ? "active" : "unactive"}
				onClick={(event) => {
					setIsMenuOpen(false);
				}}
				to="/"
			>
				home
			</Link>
		</li>
		<li>
			<Link
				className={
					location.pathname === "/artists" ? "active" : "unactive"
				}
				onClick={(event) => {
					setIsMenuOpen(false);
				}}
				to="/artists"
			>
				artists
			</Link>
		</li>
		<li>
			<a
				href="https://theprintsubscribersclub.com"
				target="_blank"
				rel="noopener noreferrer"
			>
				the print subscribers club
			</a>
		</li>
		<li>
			<Link
				className={
					location.pathname.startsWith("/ondisplay")
						? "active"
						: "unactive"
				}
				onClick={(event) => {
					setIsMenuOpen(false);
				}}
				to="/ondisplay"
			>
				on display
			</Link>
		</li>
		<li>
			<a
				href="https://instagram.com/benveniste_contemporary?igshid=NTc4MTIwNjQ2YQ=="
				target="_blank"
				rel="noopener noreferrer"
			>
				news
			</a>
		</li>
		<li>
			<Link
				className={
					location.pathname.startsWith("/about")
						? "active"
						: "unactive"
				}
				onClick={(event) => {
					setIsMenuOpen(false);
				}}
				to="/about"
			>
				about
			</Link>
		</li>
		<li>
			<Link
				className={
					location.pathname.startsWith("/contact")
						? "active"
						: "unactive"
				}
				onClick={(event) => {
					setIsMenuOpen(false);
				}}
				to="/contact"
			>
				contact
			</Link>
		</li>
		{/* <li>
			<a
				href="http://theprintsubscribersclub.com/contacto/"
				target="_blank"
				rel="noopener noreferrer"
			>
				contact
			</a>
		</li> */}
	</ul>
);

export const Header: React.FC = () => {
	const location = useLocation();
	const [bannerState, setBannerState] = React.useState<bannerState>({
		B1lightHeader: false,
		B2lightHeader: false,
		B3lightHeader: false,
	});
	const [LightMode, setLightMode] = React.useState(false);
	const [Dynamic, setDynamic] = React.useState(false);
	const [isMenuOpen, setIsMenuOpen] = React.useState(false);

	useEffect(() => {
		// Define a function to fetch banner states from Firebase
		const fetchBannerStates = async (): Promise<void> => {
			try {
				// This function queries Firebase for the specific banner states
				const data = await readBannerStates();
				setBannerState({
					B1lightHeader: data.B1lightHeader,
					B2lightHeader: data.B2lightHeader,
					B3lightHeader: data.B3lightHeader,
				});
			} catch (error) {
				console.error("Error fetching banner states:", error);
			}
		};
		fetchBannerStates();
	}, []); // Empty dependency array means it runs once on component mount

	const changeMode = React.useCallback((): void => {
		const isPortrait = window.matchMedia("(orientation: portrait)").matches;
		const scrollFactor1 = isPortrait ? 0.45 : 0.95; // Adjust this value as needed for portrait mode
		const scrollFactor2 = isPortrait ? 1.6 : 1.95; // Adjust this value as needed for portrait mode
		if (location.pathname === "/") {
			setDynamic(false);
			if (bannerState.B1lightHeader && bannerState.B2lightHeader) {
				if (
					window.scrollY >= scrollFactor1 * window.innerHeight &&
					window.scrollY <=
						scrollFactor1 * window.innerHeight +
							150 +
							0.105 * window.innerWidth
				) {
					setLightMode(false);
				} else if (
					window.scrollY >=
					scrollFactor2 * window.innerHeight +
						150 +
						0.105 * window.innerWidth
				) {
					setLightMode(false);
				} else {
					setLightMode(true);
				}
			} else if (
				bannerState.B1lightHeader &&
				!bannerState.B2lightHeader
			) {
				if (window.scrollY >= scrollFactor1 * window.innerHeight) {
					setLightMode(false);
				} else {
					setLightMode(true);
				}
			} else if (
				!bannerState.B1lightHeader &&
				bannerState.B2lightHeader
			) {
				if (
					window.scrollY >=
						scrollFactor1 * window.innerHeight +
							150 +
							0.105 * window.innerWidth &&
					window.scrollY <=
						scrollFactor2 +
							1 * window.innerHeight +
							150 +
							0.105 * window.innerWidth
				) {
					setLightMode(true);
				} else {
					setLightMode(false);
				}
			} else if (
				!bannerState.B1lightHeader &&
				!bannerState.B2lightHeader
			) {
				setLightMode(false);
			} else {
				throw new Error(
					"Unexpected conditional - Check homepage banner T/F values",
				);
			}
		} else {
			setLightMode(false);
			if (window.scrollY <= 225) {
				setDynamic(false);
			} else {
				setDynamic(true);
			}
		}
	}, [location.pathname, bannerState]);

	useEffect(() => {
		changeMode();
	}, [changeMode]);

	useEffect(() => {
		const handleScroll = (): void => {
			changeMode();
		};
		window.addEventListener("scroll", handleScroll);

		return () => {
			// Cleanup the event listener on component unmount
			window.removeEventListener("scroll", handleScroll);
		};
	}, [changeMode]);

	return (
		<header className={Dynamic ? "dynamic" : "static"}>
			<nav>
				<button
					className="burgerMenu"
					onClick={(): void => {
						setIsMenuOpen((prev) => !prev);
					}}
				>
					<div className="burgerMenuIcon">
						<img
							src={require(
								"./Resources/Images/Logos_Icons/" +
									(LightMode
										? "BurgerMenuIconWhite2.png"
										: "BurgerMenuIconBlack2.png"),
							)}
							alt="Burger Menu Icon"
						/>
					</div>
				</button>
				<Link
					className={
						location.pathname === "/" ? "active" : "unactive"
					}
					to="/"
				>
					<div className={LightMode ? "BVLogoDB" : "BVLogoLB"}>
						<h1 className={LightMode ? "LogoTextDB" : "LogoTextLB"}>
							BENVENISTE
						</h1>
						<h2 className={LightMode ? "LogoTextDB" : "LogoTextLB"}>
							CONTEMPORARY
						</h2>
					</div>
				</Link>
				{/* <!--DB stands for Dark Background for alternating LightMode schemes--> */}
				{isMenuOpen ? (
					<div
						className={
							LightMode
								? isMenuOpen
									? "navBarDB open"
									: "navBarDB"
								: isMenuOpen
									? "navBarLB open"
									: "navBarLB"
						}
					>
						<button
							className="closeBurger"
							onClick={(): void => {
								setIsMenuOpen(false);
							}}
						>
							X
						</button>
						<NavList
							location={location}
							LightMode={LightMode}
							setIsMenuOpen={setIsMenuOpen}
						/>
					</div>
				) : (
					<div className={LightMode ? "navBarDB" : "navBarLB"}>
						<NavList
							location={location}
							LightMode={LightMode}
							setIsMenuOpen={setIsMenuOpen}
						/>
					</div>
				)}
			</nav>
		</header>
	);
};
