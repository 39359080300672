import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Header } from "./Header";
import { ScrollToTop } from "./ScrollToTop";
import PopupBanner from "./PopUpBanner";
import RoutesComponent from "./Routes";

const App: React.FC = () => {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Header />
			<main>
				<RoutesComponent />
				<PopupBanner />
			</main>
		</BrowserRouter>
	);
};

export default App;
