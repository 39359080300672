import React, { type ReactElement, useEffect, useState } from "react";
import type {
	HomepageContent,
	HomepageBanner,
	PSCLatestRelease,
	Latest,
	LatestItem,
} from "./interfaces";
import {
	// extractCapitalLettersAndNormalise,
	normaliseString,
} from "./utilities";

interface InputFormProps {
	onSubmit: (formData: HomepageContent) => void;
	currentContent: HomepageContent;
}

function InputFormHomepage({
	onSubmit,
	currentContent,
}: InputFormProps): ReactElement {
	const [inputHomepageBanner1, setInputHomepageBanner1] =
		useState<HomepageBanner>({
			URL: "",
			heading: "",
			subHeading: "",
			redirect: "",
			lightHeader: false,
		});
	const [inputHomepageBanner2, setInputHomepageBanner2] =
		useState<HomepageBanner>({
			URL: "",
			heading: "",
			subHeading: "",
			redirect: "",
			lightHeader: false,
		});
	const [inputPSCLatestRelease, setInputPSCLatestRelease] =
		useState<PSCLatestRelease>({
			artistName: "",
			bookName: "",
			bookYear: "",
			link: "",
			piece1: {
				name: "",
				URL: "",
			},
			piece2: {
				name: "",
				URL: "",
			},
			piece3: {
				name: "",
				URL: "",
			},
		});
	const [inputLatestUpdate, setInputLatestUpdate] = useState<Latest>({
		banner: false,
		lightHeader: false,
		mainHeading: "",
		subHeading: "",
		artistName: "",
		URLtoArtist: "",
		setName: "",
		latestItems: [],
		numOfItems: 0,
	});
	const [inputLatestItems, setInputLatestItems] = useState<LatestItem[]>([
		{ name: "", imageSrc: "", imageAlt: "", artLink: "", price: "" },
		{ name: "", imageSrc: "", imageAlt: "", artLink: "", price: "" },
	]);

	useEffect(() => {
		// console.log(inputPSCLatestRelease);
		if (currentContent != null) {
			setInputHomepageBanner1(currentContent.homepageBanner1);
			setInputHomepageBanner2(currentContent.homepageBanner2);
			setInputPSCLatestRelease(currentContent.PSCLatestRelease);
			setInputLatestUpdate(currentContent.Latest);
			setInputLatestItems(currentContent?.Latest?.latestItems);
		}
	}, [currentContent]);

	const handleSubmit = (e: React.FormEvent): void => {
		e.preventDefault();
		const updatedLatestItems = inputLatestItems.map(
			(latestItem: LatestItem) => {
				// const artLink: string =
				// 	extractCapitalLettersAndNormalise(
				// 		inputLatestUpdate.artistName,
				// 	) + normaliseString(inputLatestUpdate.setName);
				return {
					name: latestItem.name,
					imageSrc: latestItem.imageSrc,
					imageAlt: `Work / Graphic with ${latestItem.name}`,
					artLink: latestItem.artLink,
					price: latestItem.price,
				};
			},
		);
		setInputLatestItems(updatedLatestItems);

		const newContent: HomepageContent = {
			homepageBanner1: {
				URL: inputHomepageBanner1.URL,
				heading: inputHomepageBanner1.heading,
				subHeading: inputHomepageBanner1.subHeading,
				redirect: inputHomepageBanner1.redirect,
				lightHeader: inputHomepageBanner1.lightHeader,
			},
			homepageBanner2: {
				URL: inputHomepageBanner2.URL,
				heading: inputHomepageBanner2.heading,
				subHeading: inputHomepageBanner2.subHeading,
				redirect: inputHomepageBanner2.redirect,
				lightHeader: inputHomepageBanner2.lightHeader,
			},
			PSCLatestRelease: {
				artistName: inputPSCLatestRelease.artistName,
				bookName: inputPSCLatestRelease.bookName,
				bookYear: inputPSCLatestRelease.bookYear,
				link: inputPSCLatestRelease.link,
				piece1: inputPSCLatestRelease.piece1,
				piece2: inputPSCLatestRelease.piece2,
				piece3: inputPSCLatestRelease.piece3,
			},
			Latest: {
				banner: inputLatestUpdate.banner,
				lightHeader: inputLatestUpdate.banner
					? inputLatestUpdate.lightHeader
					: false,
				mainHeading: inputLatestUpdate.mainHeading,
				subHeading: inputLatestUpdate.subHeading,
				artistName: inputLatestUpdate.artistName,
				URLtoArtist: `./artists/${normaliseString(
					inputLatestUpdate.artistName,
				)}`,
				setName: inputLatestUpdate.setName,
				latestItems: updatedLatestItems,
				numOfItems: inputLatestItems.length,
			},
		};
		if (
			window.confirm("Confirm submit ? (Refresh page to reset the form)")
		) {
			onSubmit(newContent);
		}
	};

	const handleAddLatestItem = (): void => {
		if (inputLatestItems.length < 3 && !inputLatestUpdate.banner) {
			setInputLatestItems((LatestItems) => [
				...LatestItems,
				{
					name: "",
					imageSrc: "",
					imageAlt: "",
					artLink: "",
					price: "",
				},
			]);
		} else if (inputLatestUpdate.banner) {
			window.alert("Maximum 1 item for banner when active!");
		} else {
			window.alert(
				"Maximum 3 items is the maximum number allowed for the homepage!",
			);
		}
	};

	const handleRemoveLatestItem = (itemIndex: number): void => {
		if (inputLatestItems.length > 1) {
			if (
				window.confirm(
					"Are you sure you want to delete this item? (Deletion finalised on submit)",
				)
			) {
				setInputLatestItems((latestItems) => {
					const newLatestItems = [...latestItems];
					newLatestItems.splice(itemIndex, 1);
					return newLatestItems;
				});
			}
		} else {
			window.alert(
				"1 item is the minimum number allowed for the homepage!",
			);
		}
	};

	const handleMoveLatestItem = (itemIndex: number, moveUp: boolean): void => {
		setInputLatestItems((latestItems) => {
			const newLatestItems = [...latestItems];
			if (moveUp) {
				if (itemIndex > 0) {
					// swap the current set with the one above it
					[newLatestItems[itemIndex - 1], newLatestItems[itemIndex]] =
						[
							newLatestItems[itemIndex],
							newLatestItems[itemIndex - 1],
						];
				}
			} else {
				if (itemIndex < newLatestItems.length - 1) {
					// swap the current set with the one below it
					[newLatestItems[itemIndex], newLatestItems[itemIndex + 1]] =
						[
							newLatestItems[itemIndex + 1],
							newLatestItems[itemIndex],
						];
				}
			}
			return newLatestItems;
		});
	};

	return (
		<form onSubmit={handleSubmit}>
			<h2 style={{ margin: "0 auto", width: "fit-content" }}>
				Update Homepage
			</h2>
			<h3
				style={{
					margin: "0 auto",
					width: "fit-content",
					textDecoration: "underline",
					lineHeight: "30px",
				}}
			>
				{" "}
				--- Banner 1 ---{" "}
			</h3>{" "}
			{/* Banner 1 input boxes */}
			<label>
				Banner 1 - Image URL:
				<input
					type="text"
					value={inputHomepageBanner1.URL}
					onChange={(e) => {
						setInputHomepageBanner1({
							...inputHomepageBanner1,
							URL: e.target.value,
						});
					}}
				/>
			</label>
			<br />
			<label>
				Banner 1 - Light header needed ?
				<input
					type="checkbox"
					checked={inputHomepageBanner1.lightHeader}
					onChange={(e) => {
						setInputHomepageBanner1({
							...inputHomepageBanner1,
							lightHeader: e.target.checked,
						});
					}}
				/>
			</label>
			<br />
			<label>
				Banner 1 - Redirect (Leave empty if no link):
				<input
					type="text"
					value={inputHomepageBanner1.redirect}
					onChange={(e) => {
						setInputHomepageBanner1({
							...inputHomepageBanner1,
							redirect: e.target.value,
						});
					}}
				/>
			</label>
			<br />
			<label>
				Banner 2 - Heading:
				<input
					type="text"
					value={inputHomepageBanner2.heading}
					onChange={(e) => {
						setInputHomepageBanner2({
							...inputHomepageBanner2,
							heading: e.target.value,
						});
					}}
				/>
			</label>
			<br />
			<label>
				Banner 2 - Sub-Heading:
				<input
					type="text"
					value={inputHomepageBanner2.subHeading}
					onChange={(e) => {
						setInputHomepageBanner2({
							...inputHomepageBanner2,
							subHeading: e.target.value,
						});
					}}
				/>
			</label>
			<br />
			<h3
				style={{
					margin: "0 auto",
					width: "fit-content",
					textDecoration: "underline",
					lineHeight: "30px",
				}}
			>
				{" "}
				--- Banner 2 ---{" "}
			</h3>{" "}
			{/* Banner 2 input boxes */}
			<label>
				Banner 2 - Image URL:
				<input
					type="text"
					value={inputHomepageBanner2.URL}
					onChange={(e) => {
						setInputHomepageBanner2({
							...inputHomepageBanner2,
							URL: e.target.value,
						});
					}}
				/>
			</label>
			<br />
			<label>
				Banner 2 - Light header needed ?
				<input
					type="checkbox"
					checked={inputHomepageBanner2.lightHeader}
					onChange={(e) => {
						setInputHomepageBanner2({
							...inputHomepageBanner2,
							lightHeader: e.target.checked,
						});
					}}
				/>
			</label>
			<br />
			{/* <label>
				Banner 2 - Heading:
				<input
					type="text"
					value={inputHomepageBanner2.heading}
					onChange={(e) => {
						setInputHomepageBanner2({
							...inputHomepageBanner2,
							heading: e.target.value,
						});
					}}
				/>
			</label>
			<br />
			<label>
				Banner 2 - Sub-Heading:
				<input
					type="text"
					value={inputHomepageBanner2.subHeading}
					onChange={(e) => {
						setInputHomepageBanner2({
							...inputHomepageBanner2,
							subHeading: e.target.value,
						});
					}}
				/>
			</label>
			<br /> */}
			<label>
				Banner 2 - Redirect (Leave empty if no link wanted):
				<input
					type="text"
					value={inputHomepageBanner2.redirect}
					onChange={(e) => {
						setInputHomepageBanner2({
							...inputHomepageBanner2,
							redirect: e.target.value,
						});
					}}
				/>
			</label>
			<br />
			<h3
				style={{
					margin: "0 auto",
					width: "fit-content",
					textDecoration: "underline",
					lineHeight: "30px",
				}}
			>
				{" "}
				--- PSC Latest Release ---{" "}
			</h3>{" "}
			{/* Latest release input boxes */}
			<label>
				Latest Release - Artist Name:
				<input
					type="text"
					value={inputPSCLatestRelease.artistName}
					onChange={(e) => {
						setInputPSCLatestRelease({
							...inputPSCLatestRelease,
							artistName: e.target.value,
						});
					}}
				/>
			</label>
			<br />
			<label>
				Latest Release - Book Name:
				<input
					type="text"
					value={inputPSCLatestRelease.bookName}
					onChange={(e) => {
						setInputPSCLatestRelease({
							...inputPSCLatestRelease,
							bookName: e.target.value,
						});
					}}
				/>
			</label>
			<br />
			<label>
				Latest Release - Book Year:
				<input
					type="text"
					value={inputPSCLatestRelease.bookYear}
					onChange={(e) => {
						setInputPSCLatestRelease({
							...inputPSCLatestRelease,
							bookYear: e.target.value,
						});
					}}
				/>
			</label>
			<br />
			<label>
				Latest Release - Link to the Book on PSC Website:
				<input
					type="text"
					value={inputPSCLatestRelease.link}
					onChange={(e) => {
						setInputPSCLatestRelease({
							...inputPSCLatestRelease,
							link: e.target.value,
						});
					}}
				/>
			</label>
			<br />
			<h4> --- Piece 1 --- </h4>
			<label>
				Piece 1 - URL:
				<input
					type="text"
					value={inputPSCLatestRelease.piece1.URL}
					onChange={(e) => {
						setInputPSCLatestRelease({
							...inputPSCLatestRelease,
							piece1: {
								...inputPSCLatestRelease.piece1,
								URL: e.target.value,
							},
						});
					}}
				/>
			</label>
			<br />
			<label>
				Piece 1 - Name:
				<input
					type="text"
					value={inputPSCLatestRelease.piece1.name}
					onChange={(e) => {
						setInputPSCLatestRelease({
							...inputPSCLatestRelease,
							piece1: {
								...inputPSCLatestRelease.piece1,
								name: e.target.value,
							},
						});
					}}
				/>
			</label>
			<br />
			<h4> --- Piece 2 --- </h4>
			<label>
				Piece 2 - URL:
				<input
					type="text"
					value={inputPSCLatestRelease.piece2.URL}
					onChange={(e) => {
						setInputPSCLatestRelease({
							...inputPSCLatestRelease,
							piece2: {
								...inputPSCLatestRelease.piece2,
								URL: e.target.value,
							},
						});
					}}
				/>
			</label>
			<br />
			<label>
				Piece 2 - Name:
				<input
					type="text"
					value={inputPSCLatestRelease.piece2.name}
					onChange={(e) => {
						setInputPSCLatestRelease({
							...inputPSCLatestRelease,
							piece2: {
								...inputPSCLatestRelease.piece2,
								name: e.target.value,
							},
						});
					}}
				/>
			</label>
			<br />
			<h4> --- Piece 3 --- </h4>
			<label>
				Piece 3 - URL:
				<input
					type="text"
					value={inputPSCLatestRelease.piece3.URL}
					onChange={(e) => {
						setInputPSCLatestRelease({
							...inputPSCLatestRelease,
							piece3: {
								...inputPSCLatestRelease.piece3,
								URL: e.target.value,
							},
						});
					}}
				/>
			</label>
			<br />
			<label>
				Piece 3 - Name:
				<input
					type="text"
					value={inputPSCLatestRelease.piece3.name}
					onChange={(e) => {
						setInputPSCLatestRelease({
							...inputPSCLatestRelease,
							piece3: {
								...inputPSCLatestRelease.piece3,
								name: e.target.value,
							},
						});
					}}
				/>
			</label>
			<br />
			<h3
				style={{
					margin: "0 auto",
					width: "fit-content",
					textDecoration: "underline",
					lineHeight: "30px",
				}}
			>
				{" "}
				--- Recent Updates ---{" "}
			</h3>
			<br />
			<label>
				Banner Image
				<input
					type="checkbox"
					checked={inputLatestUpdate.banner}
					onChange={(e) => {
						if (inputLatestItems.length > 1) {
							if (
								window.confirm(
									"Checking this box keeps item 1 and deletes 2 & 3. Continue ?",
								)
							) {
								setInputLatestUpdate({
									...inputLatestUpdate,
									banner: e.target.checked,
								});
								setInputLatestItems((latestItems) => {
									const firstLatestItems = [...latestItems];
									firstLatestItems
										.reverse()
										.splice(0, firstLatestItems.length - 1);
									return firstLatestItems;
								});
							}
						} else {
							setInputLatestUpdate({
								...inputLatestUpdate,
								banner: e.target.checked,
							});
						}
					}}
				/>
			</label>
			<br />
			{inputLatestUpdate.banner && (
				<>
					<label>
						Light Mode for Banner ?
						<input
							type="checkbox"
							checked={inputLatestUpdate.lightHeader}
							onChange={(e) => {
								setInputLatestUpdate({
									...inputLatestUpdate,
									lightHeader: e.target.checked,
								});
							}}
						/>
					</label>
					<br />
				</>
			)}
			<label>
				Heading (i.e. New edition):
				<input
					type="text"
					value={inputLatestUpdate.mainHeading}
					onChange={(e) => {
						setInputLatestUpdate({
							...inputLatestUpdate,
							mainHeading: e.target.value,
						});
					}}
				/>
			</label>
			<br />
			<label>
				Sub-Heading (i.e. New Work Name / Sub Focus):
				<input
					type="text"
					value={inputLatestUpdate.subHeading}
					onChange={(e) => {
						setInputLatestUpdate({
							...inputLatestUpdate,
							subHeading: e.target.value,
						});
					}}
				/>
			</label>
			<br />
			<label>
				Artist Name:
				<input
					type="text"
					value={inputLatestUpdate.artistName}
					onChange={(e) => {
						setInputLatestUpdate({
							...inputLatestUpdate,
							artistName: e.target.value,
						});
					}}
				/>
			</label>
			<br />
			<label>
				Name of Set:
				<input
					type="text"
					value={inputLatestUpdate.setName}
					onChange={(e) => {
						setInputLatestUpdate({
							...inputLatestUpdate,
							setName: e.target.value,
						});
					}}
				/>
			</label>
			{inputLatestItems.map((latestItem: LatestItem, latestItemIndex) => (
				<div key={`${inputLatestUpdate.setName}item${latestItemIndex}`}>
					<div>
						<h3>Item {latestItemIndex + 1}</h3>
					</div>
					<div>
						<label>
							Name of Item:
							<input
								type="text"
								value={latestItem.name}
								onChange={(e) => {
									setInputLatestItems((latestItems) => {
										const newLatestItems = [...latestItems];
										newLatestItems[latestItemIndex].name =
											e.target.value;
										return newLatestItems;
									});
								}}
							/>
						</label>
						<br />
						<label>
							Item URL (Firebase Key)
							<input
								type="text"
								value={latestItem.imageSrc}
								onChange={(e) => {
									setInputLatestItems((latestItems) => {
										const newLatestItems = [...latestItems];
										newLatestItems[
											latestItemIndex
										].imageSrc = e.target.value;
										return newLatestItems;
									});
								}}
							/>
						</label>
						<br />
						<label>
							Price (Leave blank for no entry)
							<input
								type="text"
								value={latestItem.price}
								onChange={(e) => {
									setInputLatestItems((latestItems) => {
										const newLatestItems = [...latestItems];
										newLatestItems[latestItemIndex].price =
											e.target.value;
										return newLatestItems;
									});
								}}
							/>
						</label>
						<br />
						<label>
							Link to Art on Site (Leave blank for no entry)
							<input
								type="text"
								value={latestItem.artLink}
								onChange={(e) => {
									setInputLatestItems((latestItems) => {
										const newLatestItems = [...latestItems];
										newLatestItems[
											latestItemIndex
										].artLink = e.target.value;
										return newLatestItems;
									});
								}}
							/>
						</label>
						<br />
						<button
							type="button"
							onClick={() => {
								handleRemoveLatestItem(latestItemIndex);
							}}
						>
							Remove Item
						</button>
						<button
							type="button"
							onClick={() => {
								handleMoveLatestItem(latestItemIndex, true);
							}}
						>
							Move Item Forward
						</button>
						<button
							type="button"
							onClick={() => {
								handleMoveLatestItem(latestItemIndex, false);
							}}
						>
							Move Item Back
						</button>
					</div>
				</div>
			))}
			<button type="button" onClick={handleAddLatestItem}>
				{" "}
				Add New Item{" "}
			</button>
			<button type="submit">Submit</button>
		</form>
	);
}
export default InputFormHomepage;
