// This the display component for each element displayed on the artist home page component
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { readAllArtistsKeyInfo } from "../Components/FirestoreDB";
import { type ArtistKeyInfo } from "../Components/interfaces";
import { Timestamp } from "firebase/firestore";

const ArtistGrid: React.FC<{ artist: ArtistKeyInfo; pathname: string }> = ({
	artist,
	pathname,
}) => {
	return (
		<Link
			key={`${artist.inId}`}
			to={`${pathname}/${artist.filePath}`}
			className="resultGrid"
		>
			<h2 className="artistLead">{artist.name}</h2>
			<div className="picture">
				<img
					src={artist.lead.src}
					alt={`Lead image of ${artist.name}`}
				/>
			</div>
		</Link>
	);
};

export const ArtistResults: React.FC = () => {
	const [artistInfo, setArtistInfo] = React.useState<ArtistKeyInfo[]>([]);
	React.useEffect(() => {
		readAllArtistsKeyInfo().then((data: ArtistKeyInfo[]) => {
			const sortedArtists = data.sort((a, b) => {
				// Get the time value for 'a'
				let timeA: number;
				if (a.createdAt instanceof Timestamp) {
					timeA = a.createdAt.toDate().getTime();
				} else if (typeof a.createdAt === "string") {
					timeA = new Date(a.createdAt).getTime();
				} else {
					timeA = 0; // Handle null or undefined however you see fit
				}

				// Get the time value for 'b'
				let timeB: number;
				if (b.createdAt instanceof Timestamp) {
					timeB = b.createdAt.toDate().getTime();
				} else if (typeof b.createdAt === "string") {
					timeB = new Date(b.createdAt).getTime();
				} else {
					timeB = 0; // Handle null or undefined however you see fit
				}

				return timeB - timeA;
			});

			setArtistInfo(sortedArtists);
		});
	}, []);

	const { pathname } = useLocation();
	return (
		<>
			<section key={`ResultsContainerGrid`} id={`ResultsContainerGrid`}>
				{artistInfo.map((artist) => (
					<ArtistGrid
						key={artist.name}
						artist={artist}
						pathname={pathname}
					/>
				))}
			</section>
		</>
	);
};
